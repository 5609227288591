import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import SendIcon from "../assets/svg/SendIcon.svg";
import { CustomFooterInput } from "../components/Input";
import adanianLogo from "../assets/svg/adanian_logo.svg";
import abc_logo from "../assets/svg/abc_logo.svg";
import phoneIcon from "../assets/svg/footer/phone.svg";
import mailIcon from "../assets/svg/footer/mail.svg";
import footerBg from "../assets/images/homepage_assets/footer_bg.webp";
import { PostRequest } from "../assets/utils/http";
import { toast } from "react-toastify";

export default function RootLayOut() {
  return (
    <>
      <Outlet />
      <Footer />
    </>
  );
}

function Footer() {
  let [email, setEmail] = useState("");
  let [firstName, setFirstName] = useState("");

  const submit = async (e) => {
    e.preventDefault();
    console.debug("Submitting like a boss!");
    toast.success("Submitting");

    if (!firstName || !email) {
      toast.warning("All Fields Must Be Filled!");
      return
    }

    // let data = {
    //   individual_name: `${firstName} `,
    //   email,
    // };

    let summitRequest = await PostRequest("/subscriber", {email});
    if (summitRequest.message === "success") {
      toast.success("Form submitted Successfully!");
      console.log("Submitted");
      // setNotification("Form Submitted Successfully");
    } else {
      toast.success("Internal server error!");
      // setNotification("Internal Server Error");
    }
  };

  let companyLinks = [
    { id: 1, title: "Media Kit", route: "/" },
    { id: 2, title: "Testimonials", route: "/" },
    { id: 3, title: "ABC App Collection", route: "/" },
    { id: 4, title: "Community Links", route: "/" },
    { id: 5, title: "Career", route: "/careers" },
  ];

  let companyLinkTwo = [
    {
      id: 1,
      title: "Inclusivity Values",
      route: "/",
    },
    { id: 2, title: "Community Guidelines", route: "/" },
    { id: 3, title: "Terms & Condition", route: "/" },
    { id: 4, title: "Privacy Policy", route: "/privacy-policy" },
    { id: 5, title: "Cookie Policy", route: "/cookie-policy" },
  ];

  let action = [
    {
      id: 1,
      icon: phoneIcon,
      action: "Main Support Line",
      act: "+254 722 703239",
    },
    {
      id: 2,
      icon: mailIcon,
      action: "General Inquiries",
      act: "info@africablockchaincenter.com",
    },
    // {
    //   id: 3,
    //   icon: printerIcon,
    //   action: 'Security Profile',
    //   act: 'Download our Profile',
    // },
  ];
  /**
   *
   * @param {import("react").ChangeEvent} event
   */
  const handleChange = (event) => {
    if (event.target.name == "email") {
      setEmail(event.target.value);
    }
    if (event.target.name == "firstname") {
      setFirstName(event.target.value);
    }
  };

  return (
    <footer
      style={{
        backgroundImage: `url(${footerBg})`,
      }}
      className="wrapperPadding"
    >
      <section className="container-fluid footer_section_two">
        <div className="row">
          <section className="col-md-7">
            <h3 className="footer_section_two_header">
              We’re making web3 adoption the future of solutioning.
            </h3>
            <p className="footer_section_two_sub_text">
              We actively engage in training web3 engineers to build solutions
              in Africa. 💪
            </p>
          </section>
          <section className="col-md-5">
            <form action="">
              <CustomFooterInput
                name="firstname"
                placeholder=""
                label="Full Name"
                value={firstName}
                onChange={handleChange}
                type="text"
              />
              <CustomFooterInput
                name="email"
                placeholder=""
                label="Email"
                value={email}
                onChange={handleChange}
                type="email"
              />
              <div className="footer_form_sub_action">
                <input type="checkbox" name="" />
                <p>Good to have you on board, Subscribe to our offer lists</p>
              </div>
              <button className="footer_form_btn " onClick={submit}>
                Submit
              </button>
              <div className="d-flex justify-content-center footerBtnWrapper">
                <p
                  className="text-center footer_form_footer_text"
                  style={{ width: "70%" }}
                >
                  By submitting your email, you are choosing join our mission to
                  build lasting and sustainable web3 solutions.
                </p>
              </div>
            </form>
          </section>
        </div>
      </section>
      <section className="footer_partnership mb-5">
        <img src={abc_logo} alt="" />
        <img src={adanianLogo} className="lab_footer_logo" alt="" />
      </section>

      <hr />

      <div className="row">
        <div className="col-md-5">
          <h4 className="footer_info_header">About Africa Blockchain Center</h4>
          <p className="footer_info_text">
            Africa Blockchain Center aims to build a blockchain ecosystem in
            Africa that breeds highly skilled blockchain engineers who will
            utilize every facet of the technology in building highly robust
            applications in Africa that will evolve society and the world as a
            whole as well as create demand for blockchain in Africa
          </p>

          <p className="footer_info_text">
            The ABC also looks to manage strategic relationships and investments
            with key consortia that can be leveraged off across parties.
          </p>
        </div>
        <div className="col-md-7">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4">
                  <h4 className="footer_info_header">Company</h4>
                  <ul className="footer_list_actions">
                    {/* {companyLinks.map((_) => (
                      <li key={_.id}>
                        <Link to={_.route}>{_.title}</Link>
                      </li>
                    ))} */}
                    <li>
                      <Link to="/community">Community Guidelines</Link>
                    </li>
                    <li>
                      <Link to="/careers">Career</Link>
                    </li>
                    <li>
                      <Link to="/community-links">Community Links</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <h4 className="footer_info_header">Company</h4>
                  <ul className="footer_list_actions">
                    {/* {companyLinkTwo.map((_) => (
                      <li key={_.id}>
                        <Link to={_.route}>{_.title}</Link>
                      </li>
                    ))} */}

                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/cookie-policy">Cookie Policy</Link>
                    </li>
                    <li>
                      <Link to="/inclusivity-values">Inclusivity Values</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {action.map((_) => (
                <div
                  key={_.id}
                  className="d-flex align-items-center footer_action_container"
                >
                  <img src={_.icon} alt="" />
                  <div>
                    <h5>{_.action}</h5>
                    <p>{_.act}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
