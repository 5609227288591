import React from 'react'
import img1 from '../../assets/svg/training/train1.svg'
import img2 from '../../assets/svg/training/train2.svg'
import img3 from '../../assets/svg/training/train3.svg'
import { Link } from 'react-router-dom'

export default function OurTraining() {
  let sections = [
    {
      id: 1,
      title: 'Blockchain for Leaders',
      body:
        'This module will focus on providing an in-depth introduction to blockchain for beginners be it the private sector, governments and the general public.',
      route: '',
      icon: img1,
    },
    {
      id: 2,
      title: 'Blockchain Programming',
      body:
        'This will focus on developing  smart contracts, networks and  enterprise development, aimed  an breeding blockchain engineers  and is built around real corporate  cases.',
      route: '',
      icon: img2,
    },
    {
      id: 3,
      title: 'NFT Masterclasses',
      body: 'Purely aimed on developing  skills for NFT development',
      route: '',
      icon: img3,
    },
  ]

  return (
    <div className="">
      <h3 className="text-center training_header">Our Training Programs</h3>
      <h6 className="text-center training_sub_header">
        The 5 month curriculum is designed for individuals with intermediary
        experience in software engineering.
      </h6>
      <div className="container-fluid">
        <div className="row">
          {sections.map((section) => (
            <div className="col-md-4">
              <div className="training_section">
                <div>
                  <img src={section.icon} alt="" />
                  <h3>{section.title}</h3>
                  <p>{section.body}</p>
                </div>
                <div>
                  <Link to={section.route}>Read More</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <h6 className="text-center sub_text_training">
        Learning programs and certification is offered in partnership with{' '}
        <br />
        Distributed Labs
      </h6>
    </div>
  )
}
