import React from "react";
import {
  AnchorLink,
  AnchorLinkHeadera,
  HeaderaBtn,
  Hederabtn,
  JoinLink,
  LinkButton,
  ModalBtn,
} from "../Button";
import { RiArrowRightUpLine } from "react-icons/ri";
import { BiGridVertical } from "react-icons/bi";
import Img from "../../assets/images/headera/Mask group.png";
import Image1 from "../../assets/images/headera/Ellipse 1108.png";
import Image2 from "../../assets/images/headera/Ellipse 1109.png";
import Image3 from "../../assets/images/headera/Ellipse 1110.png";

export default function Audience() {
  let ValueOne = [
    {
      id: 1,
      title: "Technology Students",
      text: "Primarily targeting university tech students who are passionate about the Hedera Hashgraph Distributed Ledger Technology (DLT).",
      icon: Image1,
    },
    {
      id: 2,
      title: "Young Professionals",
      text: "Early-career professionals looking to pivot into the web3 industry.",
      icon: Image3,
    },
    {
      id: 3,
      title: "Innovators and Entrepreneurs",
      text: "Individuals with innovative ideas seeking to leverage using Hedera Hashgraph Distributed Ledger Technology (DLT).",
      icon: Image2,
    },
  ];

  return (
    <div className="headera_container mb-5 ">
      <div className="row ">
        <h3 className="text-center"></h3>
        <h3 className="text-center training_header">Our Target Audience</h3>
        <div className="headera_container">
          <div className="headera_statment gap-20">
            {ValueOne.map((_) => (
              <div key={_.id}>
                <div>
                  <img alt="" src={_.icon} style={{maxWidth:"100%"}} className="mt-5" />
                  <h3 className="px-4">{_.title}</h3>
                </div>
                <p>{_.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
