import React from 'react'
import HeadPhone from '../../assets/svg/leverageIcons/headphone.svg'
import Chart from '../../assets/svg/leverageIcons/chart.svg'
import Rnd from '../../assets/svg/leverageIcons/rnd.svg'
import Inbound from '../../assets/svg/leverageIcons/inbound.svg'

export default function Leveraging() {
  let points = [
    {
      id: 1,
      icon: HeadPhone,
      heading: 'Capacity Building',
      body:
        'Train, upskill and capacity building to the private sector, public sector, institutions and individuals.',
    },
    {
      id: 2,
      icon: Chart,
      heading: 'Venture Building',
      body:
        'Start-up venture building with services including fundraising and advisory services with the aim to exit at series A, B or C.',
    },
    {
      id: 3,
      icon: Rnd,
      heading: 'Blockchain for R&D',
      body:
        'This is for companies & projects. We are always able to choose and use the right tool for the job.',
    },
    {
      id: 4,
      icon: Inbound,
      heading: 'Inbound Blockchain',
      body:
        'In-house development and integration of blockchain solutions for Ada labs companies and partners.',
    },
  ]

  return (
    <section className="">
      <h3 className="text-center training_header">
        Leveraging on
        <br /> Blockchain for business
      </h3>
      <p className="text-center" style={{ marginBottom: 100 }}>
        With extensive experience across blockchains and protocols, our main
        goal at ABC is to be a pan-african
        <br /> leader in blockchain development across multiple chains and
        create a resource pool of talent to be the
        <br />
        home of blockchain.
      </p>
      <div className="row">
        {points.map((point) => (
          <div className="col-md-3" key={point.id}>
            <div className="d-flex flex-column align-items-center justify-content-between leverage_sec">
              <img src={point.icon} alt="" />
              <h6>{point.heading}</h6>
              <p className="text-center" style={{ width: '70%' }}>
                {point.body}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
