import React from 'react'
import { RegisterBtn } from '../../components/Button'

export default function SubmitBanner() {
  return (
    <div className="d-flex align-items-center flex-column">
      <section className="info_section mt-5">
        <h3 className="text-center">
          ABC Leaders Summit <br />
          #BLOCKCHAINIZEafrica24
        </h3>

        <p className="text-center">
          Africa Blockchain Center aims to lead efforts purely focused on
          capacity building in blockchain technology engineering focusing across
          multiple protocols that leads to the development of decentralized
          application development, actively researching blockchain use cases to
          develop in-house technology and pilot solutions across lines of
          business in Africa. Connecting leading technologists and partners, the
          company aims to actualise enterprise-grade blockchain tools to drive
          industry standards and deliver value to various vital sectors.
        </p>
        <div className="d-flex justify-content-center">
          <RegisterBtn />
        </div>
      </section>
    </div>
  )
}
