import React from 'react'
import FixedWidthWrapper from '../FixedWidthWrapper'
import img from '../../assets/images/feat_img_3.png'
import img2 from '../../assets/images/empty_state/past_events.png'

export default function PastEvents() {
  return (
    <section className="past_event_cont">
      <h3 className="FONT_FAM_II text-center">Past Events</h3>
      <hr style={{ width: '80%', borderColor: '#979797', marginBottom: 40 }} />
      <FixedWidthWrapper>
        <p className="FONT_DM_SANS">
          Are you prepared for the disruption that blockchain technology and
          other emerging technologies like Artificial Intelligence (AI) is
          presenting as part of the Fourth Industrial Revolution (4IR)?
        </p>
      </FixedWidthWrapper>

      <section className="d-flex flex-column justify-content-center align-items-center">
        <img src={img2} alt="" style={{ maxWidth: '50%' }} />
        <h5
          style={{
            textAlign: 'center',
            fontSize: 30,
            marginTop: 50,
          }}
          className="FONT_FAM_II"
        >
          No Past Events
        </h5>
      </section>

      {/* <section className="past_event_wrapper">
        {[0, 1, 2, 3, 4, 5].map((_) => (
          <div key={_ + 1} className="past_event_post">
            <div style={{ backgroundImage: `url(${img})` }}></div>
            <div>
              <h5 className="FONT_DM_SANS">Nov 25, 2022</h5>
              <h2 className="FONT_DM_SANS">
                The Blockchain Event in Fort Lauderdale
              </h2>
              <h6 className="FONT_DM_SANS">Agenda</h6>
              <p className="FONT_DM_SANS">
                The purchase of crypto entails a risk. The value of crypto can
                fluctuate and capital involved in a crypto transaction is
                subject to market volatility and loss.{' '}
              </p>
            </div>
          </div>
        ))}
      </section> */}
    </section>
  )
}
