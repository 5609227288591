import React from 'react'
import Navigation from '../../components/Navigation'

export default function Community() {
  return (
    <>
      <div>
        <Navigation />
        <div className="container inclu_header">
          <h3 className="FONT_FAM_II">Community Guidelines</h3>
          <h6 className="FONT_DM_SANS">Updated Feb 3, 2023</h6>
          <h5 className="FONT_DM_SANS">
            Our Code of Conduct and your pledge to be an upstanding member of
            the Blockchain Center community.
          </h5>
          <hr className="custom_hr" />
        </div>
      </div>
      <div>
        <div className="container">
          <div className="row">
            <h4>We're empowering creatives around</h4>

            <ol>
              <li>
                <p>I will upload only work that I’ve created</p>
                Share your own work on Africa Blockchain Center.
                <ul>
                  <li>Don’t post others’ work.</li>
                  <li>Don’t take credit for others’ work.</li>
                  <li>Don’t post copyrighted or trademarked content.</li>
                  <li>
                    Don’t plagiarize others’ work. A remix/rebound is ok. An
                    exact copy/ replica is not. (See: “I will give due credit”).
                  </li>
                </ul>
                <p>
                  If your shot violates these guidelines, it will be removed
                  from Africa Blockchain Center and your account may be
                  suspended. Note: Per our Terms of Service, Africa Blockchain
                  Center has final say over whether content is appropriate.
                </p>
              </li>
              <li>
                <p>I will upload design work</p>
                <p>
                  Africa Blockchain Center is a place to upload what you’re
                  currently working on and portfolio quality work. With updates
                  to Africa Blockchain Center’s increased file size and file
                  formats, Africa Blockchain Center members should be thoughtful
                  about the design work they upload.
                </p>
                <ul>
                  <li>
                    <p>
                      Don’t share content that you would share on a
                      video-sharing platform—including promotional videos,
                      commercials, and tutorials. Video uploads should be used
                      for animation, motion design, 3D explorations, UI/UX
                      prototypes, and design work of this nature.
                    </p>
                  </li>
                  <li>
                    <p>
                      Don’t share showreels—let your collective shots tell the
                      story of your work.
                    </p>
                  </li>
                </ul>
                <p>
                  If you’re using audio to enhance your shot, please use it
                  thoughtfully. Make sure you have permission before using audio
                  that's copyrighted or stick to audio that's under creative
                  commons. If your shot violates these guidelines, it will be
                  removed from Africa Blockchain Center. Note: Per our Terms of
                  Service, Africa Blockchain Center has final say over whether
                  content is appropriate.
                </p>
              </li>
              <li>
                <p>I won’t post inappropriate content</p>
                <p>
                  Africa Blockchain Center aspires to be a welcoming and
                  inclusive community. Please don’t post content that is:
                </p>
                <ol>
                  <li>Illegal</li>
                  <li>
                    Pornographic, mature in content, or gratuitously or overly
                    sexual
                  </li>
                  <li>Racist, sexist, or otherwise offensive</li>
                  <li>Shockingly graphic, grotesque, or obscene</li>
                  <li>
                    Inflammatory (e.g. name-calling, preaching, ranting,
                    stirring up controversy, or venting frustrations)
                  </li>
                </ol>

                <p>
                  We realise that nudity can be a grey area. We aim to keep
                  Africa Blockchain Center PG-13 and shots violating this
                  guideline will be removed at ABC’s discretion.
                </p>
                <p>
                  If you post inappropriate content, it may be removed by Africa
                  Blockchain Center per our Terms of Service. Africa Blockchain
                  Center has final say over whether content is appropriate. If
                  you repost content that’s been removed or continue to post
                  content that violates these guidelines your account may be
                  suspended or removed.
                </p>
                <p>
                  If you notice a Africa Blockchain Center member posting
                  inappropriate content, flag the shot or report it by
                  contacting us.
                </p>
              </li>
              <li>
                <p>I will follow the following guidelines for my profile</p>
                For all profiles:
                <ul>
                  <li>
                    Do not replace your name with an email address, website url,
                    or keywords to attempt to appear higher in search results.
                  </li>
                  <li>Do: Dan Mwangi</li>
                  <li>Don’t: Dan Mwangi • Logos • Branding • Graphic Design</li>
                </ul>
                <p>For Pro profiles: </p>
                <ul>
                  <li>
                    Do not include an email address or website url in the
                    masthead description or image on your profile (including the
                    use of special characters)
                  </li>
                  <li>
                    Do not include any profanity, offensive or hurtful language
                    in the masthead description or image of your profile.
                  </li>
                  <li>
                    Do not post known false information including onsite
                    statistics, follower counts or other misleading information
                  </li>
                  <li>
                    Do not include any inappropriate content in the masthead
                    image on your profile. Inappropriate content includes text
                    and images that are:
                    <ul>
                      <li>Illegal</li>
                      <li>
                        Pornographic, mature in content, or gratuitously or
                        overly sexual [1]
                      </li>
                      <li>Racist, sexist, or otherwise offensive</li>
                      <li>Shockingly graphic, grotesque, or obscene</li>
                      <li>
                        Inflammatory (e.g. name-calling, preaching, ranting,
                        stirring up controversy, or venting frustrations)
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>
                  We realize that nudity can be a grey area. We aim to keep
                  Africa Blockchain Center PG-13 and shots violating this
                  guideline will be removed at Africa Blockchain Center’s
                  discretion.
                </p>
                <p>
                  If you post inappropriate content, it may be removed by Africa
                  Blockchain Center per our Terms of Service. Africa Blockchain
                  Center has final say over whether content is appropriate. If
                  you repost content that’s been removed or continue to post
                  content that violates these guidelines your account may be
                  suspended or removed.
                </p>
              </li>
              <li>
                <p>I won’t spam other Africa Blockchain Center Members</p>
                <p>
                  Africa Blockchain Center provides many features that allow
                  users to interact with one-another, including messages. Please
                  don’t use these tools indiscriminately to spam or send
                  unsolicited messages to other members. If you’re caught
                  spamming other Africa Blockchain Center members, your account
                  may be suspended or removed.
                </p>
                <p>What do we consider spam?</p>
                <p>
                  Using likes, follows, and other disingenuous social gestures
                  to draw attention to your account
                </p>

                <p>
                  Linking to your own work in comments on other members’ shots
                </p>
                <p>
                  Asking for likes and/or follows in exchange for a Africa
                  Blockchain Center invitation
                </p>
                <p>
                  Leaving many comments that are short on substance to draw
                  attention to your account
                </p>
                <p>
                  Misusing site features and functionality in a way that isn’t
                  in line with their core purpose for personal gain
                </p>
                <p>
                  If you see a Africa Blockchain Center member spamming others,
                  report it by contacting us.
                </p>
              </li>
              <li>
                <p>
                  I won’t use Africa Blockchain Center invitations to draw
                  attention to my own account
                </p>
                <p>
                  Invitations should not be used as prizes for contests, but
                  given genuinely based on your interest in following someone’s
                  work
                  <p>
                    Invitation contests that require or suggest likes and
                    follows for entry is prohibited
                  </p>
                  Africa Blockchain Center’s invitation system aims to increase
                  the number of talented designers and interesting work on
                  Africa Blockchain Center —not to draw attention to your
                  profile
                </p>
                <p>
                  Please do not attempt to sell or buy Africa Blockchain Center
                  invitations or pursue fake follows on Africa Blockchain Center
                  Your account may be suspended or removed if you engage in
                  activity that violates these guidelines.
                </p>
              </li>
              <li>
                <p>I will give due credit</p>
                <p>
                  If your work is inspired by other work on Africa Blockchain
                  Center, make sure to give credit. You can do this by
                  mentioning the Africa Blockchain Center member or creating a
                  Rebound. Know that work that’s inspired by others may be
                  interpreted as stolen.
                </p>
                <p>
                  If you’re the owner of work that has been posted on Africa
                  Blockchain Center without your consent, please review our
                  Copyright Policy.
                </p>
                {/* Please link back toAfrica Blockchain Center when posting Africa Blockchain Center content elsewhere. */}
              </li>
              <li>
                <p>
                  I will be constructive in my feedback When providing criticism
                  or feedback to other Africa Blockchain Center members—be
                  helpful, not mean.
                </p>
              </li>
              <li>
                <p>
                  I will use messages to contact Africa Blockchain Center
                  members about serious work inquires only
                </p>
                <p>
                  When sending a message to a designer, please follow these
                  guidelines:
                </p>
                <ul>
                  <li>
                    Your message must be a work inquiry regarding a design job
                    or project.
                  </li>
                </ul>
                <ul>
                  <li>
                    Please personalize the messages you send—don’t send
                    boilerplate messages. They tend to feel impersonal and lack
                    the detail necessary to be appealing. This means you should
                    use their name, provide a brief description of the work or
                    job for which you’re hiring, and any details you find
                    compelling about the designer’s work on Africa Blockchain
                    Center.
                    <ul>
                      <li>
                        Please make sure the designer you’re contacting is a
                        good fit for your job or project as indicated by the
                        work, bio and skills on their profile. Many designers
                        also declare the type work in which they’re interested
                        on the message form—so make sure your message conforms
                        with their stated preferences.
                      </li>
                      <li>
                        Have a specific job or project in mind when contacting a
                        designer. Don’t ask if someone is generally looking for
                        new opportunities or if anyone they know is looking for
                        new opportunities.
                      </li>
                      <li>
                        Please don’t message designers to ask for an invitation.
                        Your messaging privileges will be revoked
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  I recognize that personal accounts are only to be used for
                  posting my personal work
                </p>
                <p>
                  All work posted under an individual player account represents
                  that account holders personal work and not the work of a team,
                  collective, community or any other group of individuals. If
                  you would like to share work created by a team, create a Team
                  account.
                </p>
              </li>
              <li>
                <p>
                  I recognize that Team accounts are for design agencies and
                  in-house design teams
                </p>
                <p>
                  When creating a Team account please follow these guidelines:
                </p>
                <p>
                  The Team account represents an agency or in-house design team
                </p>
                <p>
                  You are not selling, offering, promoting or receiving
                  compensation of any kind in exchange for seats on your Team
                  account
                </p>
                <p>
                  Each person invited to your team works as part of said agency
                  or in-house design team You are not using your Team profile to
                  spam the Africa Blockchain Center community (See Guideline #4)
                </p>
                <p>
                  Failure to follow these guidelines will result in your
                  messaging privileges being suspended or revoked.
                </p>
              </li>
              <li></li>
            </ol>
          </div>
        </div>
      </div>
    </>
  )
}
