import React from 'react'

export default function ArrowRight() {
  return (
    <svg
      width="35"
      height="14"
      viewBox="0 0 35 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.6228 7.62284C34.9668 7.27886 34.9668 6.72114 34.6228 6.37716L29.0173 0.771588C28.6733 0.427603 28.1156 0.427603 27.7716 0.771588C27.4276 1.11557 27.4276 1.67329 27.7716 2.01727L32.7543 7L27.7716 11.9827C27.4276 12.3267 27.4276 12.8844 27.7716 13.2284C28.1156 13.5724 28.6733 13.5724 29.0173 13.2284L34.6228 7.62284ZM0 7.88083H34V6.11917H0V7.88083Z"
        fill="black"
      />
    </svg>
  )
}
