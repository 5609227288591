import React from 'react'
import FixedWidthWrapper from '../FixedWidthWrapper'
import HeadPhoneIcon from '../../assets/svg/leverageIcons/headphone.svg'
import ChartIcon from '../../assets/svg/leverageIcons/chart.svg'
import RDIcon from '../../assets/svg/leverageIcons/rnd.svg'

export default function AbcBenefit() {
  let options = [
    {
      id: 1,
      title: 'Culture',
      body:
        'High degree of autonomy to define and implement things your way. Cross-disciplinary projects and tasks. A customer-centric company culture with flat hierarchy and open communication.',
      icon: HeadPhoneIcon,
    },
    {
      id: 2,
      title: 'Values',
      body:
        'We live and breathe these simple guidelines: Always Be Learning, Care For The User, Enjoy the Future of Web, Be Clear and Truthful, Strength in Web 3 Diversity & We Go Together.',
      icon: ChartIcon,
    },
    {
      id: 3,
      title: 'Learn & Grow',
      body:
        'Challenging personal and team objectives. An accelerated personal growth opportunity through close exposure to our management and training opportunities externally.',
      icon: RDIcon,
    },
  ]
  return (
    <div className="benefits">
      <h3 className="text-center FONT_FAM_II">Benefits of Joining ABC</h3>
      <FixedWidthWrapper>
        <p className="FONT_DM_SANS">
          From team events, web 3 community, working credits and swag, we do our
          best to ensure you have what you need, and a fair amount of what you
          want too!
        </p>
      </FixedWidthWrapper>

      <FixedWidthWrapper>
        <div className="benefit_dets_cont">
          {options.map((option) => (
            <div key={option.id}>
              <img src={option.icon} alt="" srcset="" />
              <h3 className="FONT_DM_SANS">{option.title}</h3>
              <p className="FONT_DM_SANS">{option.body}</p>
            </div>
          ))}
        </div>
      </FixedWidthWrapper>
    </div>
  )
}
