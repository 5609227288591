import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Bounce, ToastContainer } from "react-toastify";

import RootLayOut from './layout/RootLayOut'
import reportWebVitals from './reportWebVitals'
import LandingPage from './views/root/LandingPage'
import AboutUs from './views/root/AboutUs'
import OurSolution from './views/root/OurSolution'
import Blog from './views/root/Blog'
import Careers from './views/root/Careers'
import Events from './views/root/Events'
import ContactUs from './views/root/ContactUs'
import PrivacyPolicy from './views/root/PrivacyPolicy'
import Summit from './views/root/Summit'
import CookiePolicy from './views/root/CookiePolicy'
import Inclusivity from './views/root/Inclusivity'
import Community from './views/root/Community'
import Hubs from './views/root/Hubs'
import Headera from './views/root/Headera'

import "react-toastify/dist/ReactToastify.css";
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayOut />,
    children: [{ element: <LandingPage />, index: true }],
  },
  {
    path: "/about-us",
    element: <RootLayOut />,
    children: [{ element: <AboutUs />, index: true }],
  },
  {
    path: "/discover-us",
    element: <RootLayOut />,
    children: [{ element: <AboutUs />, index: true }],
  },
  {
    path: "/thaxabc",
    element: <RootLayOut />,
    children: [{ element: <Headera />, index: true }],
  },
  {
    path: "/our-solutions",
    element: <RootLayOut />,
    children: [{ element: <OurSolution />, index: true }],
  },
  {
    path: "/blog",
    element: <RootLayOut />,
    children: [{ element: <Blog />, index: true }],
  },
  {
    path: "/careers",
    element: <RootLayOut />,
    children: [{ element: <Careers />, index: true }],
  },
  {
    path: "/events",
    element: <RootLayOut />,
    children: [{ element: <Events />, index: true }],
  },
  {
    path: "/support",
    element: <div>Hello world!</div>,
  },
  {
    path: "/contact-us",
    element: <RootLayOut />,
    children: [{ element: <ContactUs />, index: true }],
  },
  {
    path: "/privacy-policy",
    element: <RootLayOut />,
    children: [{ element: <PrivacyPolicy />, index: true }],
  },
  {
    path: "/cookie-policy",
    element: <RootLayOut />,
    children: [{ element: <CookiePolicy />, index: true }],
  },
  {
    path: "/terms-and-conditions",
    element: <div>Hello world!</div>,
  },
  {
    path: "/summit",
    element: <RootLayOut />,
    children: [{ element: <Summit />, index: true }],
  },
  {
    path: "/inclusivity-values",
    element: <RootLayOut />,
    children: [{ element: <Inclusivity />, index: true }],
  },
  {
    path: "/community",
    element: <RootLayOut />,
    children: [{ element: <Community />, index: true }],
  },
  {
    path: "/community-links",
    element: <RootLayOut />,
    children: [{ element: <Hubs />, index: true }],
  },
  {
    path: "/admin-dashboard",
    element: <RootLayOut />,
    children: [{ element: <Blog />, index: true }],
  },
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      transition={Bounce}
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
