import React from 'react'
import FixedWidth from '../../components/FixedWidthWrapper'
import img from '../../assets/images/featured_Image.png'
import img2 from '../../assets/images/empty_state/upcoming.png'

export default function UpcomingEvents() {
  let events = [
    {
      category: 'party',
      event: 'Smart Contracts and the future of Crypto',
      address: 'The Weston Hotel Mombasa',
      month: 'Feb',
      day: 25,
      id: 1,
    },
    {
      category: 'party',
      event: 'The Bitcoin Blueprint CCI vs OP Playday',
      address: 'The Weston Hotel Mombasa',
      month: 'Feb',
      day: 25,
      id: 2,
    },
    {
      category: 'party',
      event: 'Primodal Blockchain ACCI Festival',
      address: 'The Weston Hotel Mombasa',
      month: 'Feb',
      day: 25,
      id: 3,
    },
  ]
  return (
    <section className="onGoingEvents">
      <h3 className="text-center FONT_FAM_II">
        Ongoing and
        <br /> Upcoming Events
      </h3>
      <section className="d-flex flex-column justify-content-center align-items-center">
        <img src={img2} alt="" style={{ maxWidth: '50%' }} />
        <h5
          style={{
            textAlign: 'center',
            fontSize: 30,
            marginTop: 50,
            color: '#fff',
          }}
          className="FONT_FAM_II"
        >
          No Ongoing and
          <br /> Upcoming Events
        </h5>
      </section>
      {/* <FixedWidth>
        {events.map((event) => (
          <div className="event">
            <div
              style={{ backgroundImage: `url(${img})` }}
              className="event_det_img"
            ></div>
            <div>
              <div className="event_layer1">
                <div>
                  <h3 className="event_month FONT_DM_SANS">{event.month}</h3>
                  <h5 className="event_day FONT_DM_SANS">{event.day}</h5>
                </div>
                <div>
                  <h4 className="FONT_DM_SANS event_title_2">{event.event}</h4>
                </div>
              </div>
              <div className="event_layer1 event_layer2">
                <div>
                  <h3 className="event_month FONT_DM_SANS text-primary text-capitalize fs-6 mb-2">
                    Tickets
                  </h3>
                  <h5 className="event_day FONT_DM_SANS">Free</h5>
                </div>
                <div>
                  <h3 className="event_month FONT_DM_SANS text-capitalize fs-6 mb-2">
                    Address
                  </h3>
                  <h5 className="event_day FONT_DM_SANS">{event.address}</h5>
                </div>
              </div>
            </div>
          </div>
        ))}
      </FixedWidth> */}
    </section>
  )
}
