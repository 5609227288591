import React from 'react'
import bgImg from '../../assets/images/homepage_assets/footer_bg.webp'
import Navigation from '../Navigation'
export default function BannerTwo({ body }) {
  return (
    <div
      style={{ backgroundImage: `url(${bgImg})` }}
      className="pt-2 pb-5 banner_bg_control"
    >
      <Navigation />
      {body}
    </div>
  )
}

export function BannerThree({ body }) {
  return (
    <div
      style={{ backgroundImage: `url(${bgImg})` }}
      className="pt-2 pb-5 banner_bg_controlIII"
    >
      <Navigation />
      {body}
    </div>
  )
}
