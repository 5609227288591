import React from 'react'
import Navigation from '../../components/Navigation'

export default function Hubs() {
  return (
    <>
      <div>
        <Navigation />
        <div className="container inclu_header">
          <h3 className="FONT_FAM_II">ABC Community Hubs</h3>
          <h6 className="FONT_DM_SANS">Updated Feb 3, 2023</h6>
          <h5 className="FONT_DM_SANS">
            Below are some community hubs for our Africa Blockchain Center
            interaction platforms.
          </h5>
          <hr className="custom_hr" />
          <h5>
            When you are within our community circles, we expect diligence. Here
            are some guidelines and helpful tips to keep in mind when
            interacting across Tech Community:
          </h5>
          <h6>
            The mission of our online community is to provide a platform for IT
            admins, developers, MVPs, and Microsoft employees to interact. We
            want Tech Community to be a central destination for education and
            thought leadership on product news, best practices, and live events
          </h6>
        </div>
        <div className="perks text-white">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h5 className="text-center">DO:</h5>
                <ul>
                  <li>
                    Be positive, kind, and courteous. Be friendly with others on
                    the network. Feel free to provide feedback but keep it
                    constructive.
                  </li>
                  <li>Be interactive - “Like” posts and ask questions!</li>
                  <li>
                    Browse our list of Community Hubs for the products you’re
                    most interested in and join discussion conversations.
                    Community Hubs are where most of the conversations happen.
                  </li>
                  <li>
                    Post your question or comment in the applicable community!
                    Using labels like “BAAS” and ”Crypsense” help people
                    understand what product or topic you’re talking about.
                  </li>
                  <li>
                    Not sure where to post? Post your question or comment in our
                    Community Lounge in the Tech Community Discussion space, and
                    one of our community admins will assist in finding the right
                    Community Hub to post to.
                  </li>
                  <li>
                    If you ask a question and a posted answer worked for you,
                    select “Mark answer as best response.” This helps resolve
                    the thread, so other community members and visitors know you
                    received the answer you were looking for.
                  </li>
                  <li>
                    Search before you post! There is a chance that your question
                    has already been answered. The search bar at the top of the
                    site is very useful, so take advantage of it.
                  </li>
                  <li>
                    Keep private stuff private. Make sure if you post
                    confidential information, you do so responsibly and in the
                    correct place.
                  </li>
                  <li>
                    Add a profile photo and complete your profile! It’s quick,
                    easy, and helps identify your expertise and role to other
                    community members and visitors.
                  </li>
                </ul>
              </div>
              <div className="col-6">
                <h5 className="text-center">DON'T:</h5>

                <ul>
                  <li>
                    Post support or break/fix questions. The best place to get
                    product support is our Africa Blockchain Center community.
                    If you’re an IT admin, you can also open a support ticket
                    through the admin center. Opening a support ticket is the
                    best way to track an issue and ensure it gets escalated as
                    needed so you’re back up and running as quickly as possible.
                  </li>
                  <li>
                    Post solicitation, third-party links, content-free posts, or
                    spam. First-time offenders will be given a warning, but
                    future offenses may result in a temporary or permanent
                    account ban. However, we have a zero-tolerance policy on
                    spam. Violators who break the no spam rule will be
                    permanently banned from the community on multiple instances
                    of spamming.
                  </li>
                  <li>
                    Don’t post personal information about yourself or anyone
                    else, such as email addresses, phone numbers, physical
                    addresses, etc. If you want to connect with another
                    community member or employee, ask for their permission to
                    send them a direct message through our site’s Private
                    Message feature.
                    <br />
                    Note: These guidelines outlined above still apply to private
                    messages.
                  </li>
                  <li>
                    Be negative. We welcome constructive feedback here, but
                    don’t post personal or harassing remarks, sarcasm, or blame;
                    it’s not helpful. Use crazy fonts, profanity, or ALL CAPS.
                    Not only are these things inappropriate, but they’re also
                    unnecessary.
                  </li>
                  <li>
                    Wander off topic. Don’t post about religion, politics or
                    other things that don’t belong here. We do have a dedicated
                    space designed to be an online version of an office water
                    cooler called, Away From Keyboard. However, the rule of no
                    religion, politics and other taboo topics still applies
                    here. Keep it light and fun!
                  </li>
                  <li>
                    Share content from a private group. Respect your colleagues
                    and our acceptable usage policy, and don’t share
                    confidential information outside the community’s private
                    groups.
                  </li>
                  <li>
                    Harvest user data. Some members may share personal
                    information here. If you spam members from this network,
                    Microsoft may take legal action.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
