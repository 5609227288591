import React, { useEffect } from 'react'
import img from '../../assets/images/featured_Image.png'
import img2 from '../../assets/images/feat_img_2.png'
import axios from 'axios'
import emptyState from '../../assets/images/empty_state/blog_state.png'

export default function Posts() {
  let dummyPosts = [
    {
      id: 1,
      title: 'Crypto in The Community',
      body:
        'Selecting Accounts gives you access to the custodial side of your web3 wallet. Here you can access our world class brokerage services, including the ability to buy, sell, swap, and earn rewards (for eligible users) on your crypto. Funds in your Accounts are held and secured by Blockchain.com. This stability allows people and businesses to continue making transactions using a currency.',
    },
    {
      id: 2,
      title: 'NFT as a Unity of Gameplay ',
      body: `An NFT is a digital smart contract that represents a real item of value, whether digital or physical. Each NFT has its own individual history that can be traced back to the original owner, and they offer numerous benefits:
        They help prove the authenticity of digital assets and ownership.
        Artists can sell their work directly to fans without intermediaries.`,
    },
    {
      id: 3,
      title: 'NFT as a Unity of Gameplay ',
      body: `An NFT is a digital smart contract that represents a real item of value, whether digital or physical. Each NFT has its own individual history that can be traced back to the original owner, and they offer numerous benefits:
          They help prove the authenticity of digital assets and ownership.
          Artists can sell their work directly to fans without intermediaries.`,
    },
    {
      id: 4,
      title: 'NFT as a Unity of Gameplay ',
      body: `An NFT is a digital smart contract that represents a real item of value, whether digital or physical. Each NFT has its own individual history that can be traced back to the original owner, and they offer numerous benefits:
          They help prove the authenticity of digital assets and ownership.
          Artists can sell their work directly to fans without intermediaries.`,
    },
    {
      id: 5,
      title: 'NFT as a Unity of Gameplay ',
      body: `An NFT is a digital smart contract that represents a real item of value, whether digital or physical. Each NFT has its own individual history that can be traced back to the original owner, and they offer numerous benefits:
          They help prove the authenticity of digital assets and ownership.
          Artists can sell their work directly to fans without intermediaries.`,
    },
    {
      id: 6,
      title: 'NFT as a Unity of Gameplay ',
      body: `An NFT is a digital smart contract that represents a real item of value, whether digital or physical. Each NFT has its own individual history that can be traced back to the original owner, and they offer numerous benefits:
          They help prove the authenticity of digital assets and ownership.
          Artists can sell their work directly to fans without intermediaries.`,
    },
  ]

  useEffect(() => {
    fetchRecentPost()
  }, [])

  let fetchRecentPost = async () => {
    let res = await axios.get(
      'https://backend.theafricablockchaincenter.com/posts/category/all?page=1',
    )
    console.log(res)
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img src={emptyState} alt="" style={{ maxWidth: '50%' }} />
      <h6
        style={{ textAlign: 'center', fontSize: 40, marginTop: 50 }}
        className="FONT_FAM_II"
      >
        No Past Blogs
        <br /> were posted by ABC
      </h6>
    </div>
  )

  return (
    <div className="blog_section">
      {/* highlighted post */}
      <section className="post_highlight">
        <div style={{ backgroundImage: `url(${img2})` }}></div>
        <div>
          <h6>Nov 25, 2022</h6>
          <h3>The best of custody and self-custody in one app</h3>
          <p>
            The Blockchain.com Wallet is the world’s first and only hybrid
            crypto wallet, giving you the best of custodial and self-custodial
            crypto in a single app. Today we’re introducing the first in a
            series of improvements to the iOS and Android apps that will allow
            you to more easily navigate between these two worlds.
            <br /> What’s new in your Blockchain.com Wallet app You can now
            easily switch between your Accounts and Private Key Wallet by
            tapping the dropdown menu in the top left corner of the home screen.
          </p>
          <div className="d-flex flex-row justify-content-between">
            <h6>By Amisi Kevin</h6>
            <h6>Read More</h6>
          </div>
        </div>
      </section>

      <section className="blog_post_summary">
        {dummyPosts.map((post) => (
          <div>
            <div
              className="post_sum_img"
              style={{ backgroundImage: `url(${img})` }}
            ></div>
            <div className="post_sum_content">
              <h6>Jan 7,2022</h6>

              <h3>{post.title}</h3>
              <p>{post.body}</p>
            </div>
          </div>
        ))}
      </section>
    </div>
  )
}
