import React from 'react'
import { CustomBtn, LinkButton, RoutingBtn } from '../Button'
import adanianLogo from '../../assets/svg/adanian_logo.svg'

export default function EventBanner() {
  return (
    <div className="event_banner_cont">
      <div className="event_banner_info">
        <CustomBtn textClr="#000" btnClr="#fff" btnTitle="Next Event" />
        <h3 className="FONT_FAM_II">
          ABC Leaders Summit #BLOCKCHAINIZEafrica24
        </h3>

        <p className="FONT_DM_SANS">
          Are you prepared for the disruption that blockchain technology and
          other emerging technologies like Artificial Intelligence (AI) is
          presenting as part of the Fourth Industrial Revolution (4IR)?
        </p>

        {/* <div className="d-flex align-items-center mb-3">
          <p
            className="FONT_DM_SANS"
            style={{ marginBottom: 0, marginRight: 10 }}
          >
            Sponsored By
          </p>
          <img src={adanianLogo} style={{ maxWidth: '30%' }} />
        </div> */}

        <div>
          {/* <RoutingBtn
            btnClr="transparent"
            btnTitle="ABC Leaders' Summit"
            textClr="#fff"
            hasBorder={true}
            borderColor="#fff"
            route="/summit"
          /> */}

          {/* <CustomBtn
            btnClr="transparent"
            btnTitle="18th Jan 2023"
            textClr="#fff"
            hasBorder={true}
            borderColor="#fff"
          /> */}

          {/* <CustomBtn
            btnClr="transparent"
            btnTitle="Tickets - Free"
            textClr="#fff"
            hasBorder={true}
            borderColor="#fff"
          /> */}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        {/* <div className="event_timer_container">
          <div>
            <div>
              <h5>Start</h5>
              <h6>2:30pm</h6>
            </div>
            <div>
              <h5>JAN</h5>
              <h2>18</h2>
              <h5>FRIDAY</h5>
            </div>
            <div>
              <h5>End</h5>
              <h6>6:30pm</h6>
            </div>
          </div>

          <hr />

          <div className="timer_wrapper">
            <div></div>
            <div></div>
            <p>:</p>
            <div></div>
            <div></div>
            <p>:</p>
            <div></div>
            <div></div>
          </div>
        </div> */}
      </div>
    </div>
  )
}
