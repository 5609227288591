import React from 'react'
import BannerOne from '../../components/BannerSection/BannerOne'
import Jumbotron from '../../components/about/Jumbotron'
import '../../assets/stylings/AboutStyle.css'
import Mission from '../../components/about/Mission'
import Banner from '../../assets/images/about/banner.jpeg'
import Heroes from '../../components/about/Heroes'

export default function AboutUs() {
  return (
    <>
      <BannerOne body={<Jumbotron />} />
      <div className="section">
        <h4 className="text-center">We do things differently...</h4>
        <div>
          <p>
            Africa Blockchain Center aims to build a blockchain ecosystem in
            Africa that breeds highly skilled blockchain engineers who will
            utilise every facet of the technology in building highly robust
            applications that will evolve society and the world as a whole as
            well as create demand for blockchain in Africa. We focus on the
            details of everything we do. All to help businesses around the world
            focus on what's most important to them. We take pride in this.
          </p>
        </div>
      </div>

      <div className="section_two d-flex justify-content-center">
        <p>
          What if blockchain adoption didn't mean crypto adoption? We are
          creating the next class of blockchain, built on the principle of
          economic sustainability.
        </p>
      </div>
      <Mission />
      {/* banner */}
      <section
        className="about_banner_two"
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <div className="about_banner_overlay">
          <h3 className="">
            If you are all about doing things differently, breaking the norms
            and challenging the status quo, then reach out to our team.
          </h3>
        </div>
      </section>

      <Heroes />
    </>
  );
}
