import React from 'react'

export default function SolutionInput({ placeholder, value, setValue }) {
  return (
    <input
      placeholder={placeholder}
      className="solution_input"
      value={value}
      onChange={({ target: { value } }) => setValue(value)}
      required
    />
  )
}

export function SolutionDropDown({ children, placeholder, value, setValue }) {
  return (
    <select
      required
      className="solution_input"
      onChange={({ target: { value } }) => setValue(value)}
      value={value}
    >
      <option value="">{placeholder}</option>
      {children}
    </select>
  )
}
