import React from 'react'
import BannerThree from '../../components/BannerSection/BannerThree'
import EventBanner from '../../components/events/EventBanner'
import '../../assets/stylings/eventStyling.css'
import UpcomingEvents from '../../components/events/UpcomingEvents'
import PastEvents from '../../components/events/PastEvents'

export default function Events() {
  return (
    <>
      <BannerThree body={<EventBanner />} />
      <UpcomingEvents />
      <PastEvents />
    </>
  )
}
