import React from 'react'
import FixedWidthWrapper from '../FixedWidthWrapper'
import CustomInput, { CustomTextInput } from './CustomInput'
import Avatar from '../../assets/images/contact/avatar.png'
import { FcBusinessContact } from 'react-icons/fc'

export default function ContactContent() {
  return (
    <section>
      <FixedWidthWrapper>
        <div className="contact_wrapper_I">
          <section className="contact_section">
            <h4 className="FONT_DM_SANS">Working hours</h4>
            <hr />
            <div>
              <h5 className="FONT_DM_SANS">Monday To Friday</h5>
              <h5 className="FONT_DM_SANS">9:00 AM to 8:00 PM </h5>
            </div>

            <h6 className="FONT_DM_SANS">Our Support Team is available 24/7</h6>
          </section>
          <section className="contact_section">
            <h4 className="FONT_DM_SANS">Contact Us</h4>
            <hr />
            <div>
              <h5 className="FONT_DM_SANS">+254 722 703239</h5>
            </div>

            <h6 className="FONT_DM_SANS">info@theafricablockchaincenter.com</h6>
          </section>
        </div>
      </FixedWidthWrapper>

      <FixedWidthWrapper>
        <div className="form_map">
          <div>
            <h3 className="map_header FONT_FAM_II">Get in touch </h3>
            <div className="d-flex align-items-center mb-2">
              <div>
                {/* <img src={Avatar} alt="" /> */}
                <FcBusinessContact size={34} />
              </div>
              <p className="map_intro ms-3">
                Need help with Web 3? Use the form below or email us at
                info@theafricablockchaincenter.com
              </p>
            </div>

            <form action="">
              <CustomInput placeholder="" label="Name" />
              <CustomInput placeholder="" label="Email" />
              <CustomTextInput
                placeholder="Type Your Query Here"
                label="Message"
              />
              <button
                style={{
                  backgroundColor: '#6088EE',
                  border: 'none',
                  color: '#fff',
                  padding: `15px 30px`,
                }}
              >
                Send My Message
              </button>
            </form>
          </div>
          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.400256683415!2d36.80544!3d-1.262307!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x22ad5b25e1a76ce2!2sM%C3%B6venpick%20Residences%20Nairobi!5e0!3m2!1sen!2sng!4v1673864970005!5m2!1sen!2sng"
              style={{
                border: 0,
                width: '100%',
                height: 650,
                marginTop: 6,
              }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </FixedWidthWrapper>
    </section>
  )
}
