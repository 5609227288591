import React from "react";
// images
import Vector1 from "../../assets/images/headera/Vector (1).png";
import Vector2 from "../../assets/images/headera/Vector (2).png";
import Vector3 from "../../assets/images/headera/Vector (3).png";
import Vector4 from "../../assets/images/headera/Vector (4).png";
import Vector5 from "../../assets/images/headera/Vector (5).png";
import Vector6 from "../../assets/images/headera/Vector (6).png";

export default function Banner4() {
  let ValueOne = [
    {
      id: 1,
      title: "Application",
      text: "Take the first step by filling out and submitting your application via our convenient online portal.",
      icon: Vector1,
    },
    {
      id: 2,
      title: "Selection",
      text: "We'll carefully assess all applications and inform successful candidates of their acceptance into the program.",
      icon: Vector2,
    },
    {
      id: 3,
      title: "Onboarding",
      text: "Join us for an informative onboarding session to gain insight into the program's structure and what's expected of you.",
      icon: Vector3,
    },
  ];

  let ValueTwo = [
    {
      id: 1,
      title: "Training Sessions",
      text: "Engage in our comprehensive training program, featuring weekly sessions designed for both online and offline participation.",
      icon: Vector4,
    },
    {
      id: 2,
      title: "Projects and Hackathons",
      text: " Get hands-on experience by collaborating on real-world projects and showcasing your skills in exciting hackathon competitions.",
      icon: Vector5,
    },
    {
      id: 3,
      title: "Completion and Certification",
      text: "Successfully fulfill all program requirements to earn your certificate and demonstrate your mastery of the material.",
      icon: Vector6,
    },
  ];
  return (
    <section
      className=" mt-2"
      // style={{ backgroundImage: `url(${bgImg2})`, position: "relative" }}
    >
      <div className="our_vision_container">
        <h3 className="text-center our_values_header mb-0">How It Works</h3>
        <p
          className="text-center FONT_DM_SANS"
          style={{ color: "grey", fontSize: 36, fontWeight: 500 }}
        >
          DLT Africa Regional  Developer Program
        </p>
        <div className="headera_container1">
          <div className="value_statment ">
            {ValueOne.map((_) => (
              <div key={_.id} style={{ borderRightColor: "transparent" }}>
                <div className="mt-5">
                  <img alt="" src={_.icon} />
                  <h3>{_.title}</h3>
                </div>
                <p>{_.text}</p>
              </div>
            ))}
          </div>
          <div
            className="value_statment"
            style={{ borderBottom: "none", borderRight: "none" }}
          >
            {ValueTwo.map((_) => (
              <div key={_.id} style={{ borderRightColor: "transparent" }}>
                <div>
                  <img alt="" src={_.icon} />
                  <h3>{_.title}</h3>
                </div>

                <p>{_.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
