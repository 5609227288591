
import Navigation from "../../components/Navigation";
import bgImg from "../../assets/images/homepage_assets/bg1.webp";
import bgImg2 from "../../assets/images/homepage_assets/middle_bg.webp";
import "../../assets/stylings/landingPageStyling.css";
import adanianLogo from "../../assets/svg/adanian_logo.svg";
import abc_logo from "../../assets/svg/abc_logo.svg";
import headeraLogo from "../../assets/images/partner_logos/Group 1171274855.svg";
import Banner1 from "../../components/headera/Banner1";
import "../../assets/stylings/headera.css";
import { HeaderaAboutBtn } from "../../components/Button";
import Prize from "../../components/headera/Prize";
import Banner2 from "../../components/headera/Banner2";
import Banner3 from "../../components/headera/Banner3";
import Banner4 from "../../components/headera/Banner4";
import Banner5 from "../../components/headera/Banner5";
import Audience from "../../components/headera/Audience";
import Countries from "../../components/headera/Countries";
import Join from "../../components/headera/Join";
import Program from "../../components/headera/Program";
import HeaderaNavigation from "../../components/headera/HeaderaNavigation";

export default function Headera() {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${bgImg})` }}
        className="pt-2 pb-5 banner_bg_control"
      >
        <HeaderaNavigation/>
        <Banner1 />
        <div className="banner_bg_control_two">
          <section className="info_section info_sub_section pt-5">
            <div className="d-flex justify-content-center">
              {/* <HeaderaAboutBtn /> */}
            </div>

            <h3 className="text-center  our_values_header">
              DLT Africa Regional  Developer Program
            </h3>
            <p
              className="text-center mt-2 "
              style={{ fontSize: 16, width: "100%"}}
            >
              The DLT Africa Regional Developer Program is a comprehensive
              training and development initiative funded by The Hashgraph
              Association (THA). Meticulously crafted to introduce participants
              to the dynamic realm of distributed ledger
              technology (DLT), this program places a particular emphasis on the
              Hedera network, a leading enterprise-grade public ledger.
              Leveraging the collective expertise of Adanian Labs, The Africa
              Blockchain Centre, and The Hashgraph Association, the program
              promises a top-tier learning experience, covering pivotal topics
              in the blockchain space.
            </p>
          </section>
          <section className="headera_partnership  p-5 mt-5 ">
            <img
              src={headeraLogo}
              className="logo"
              alt=""
              // style={{ width: "40%" }}
            />
            <img
              src={adanianLogo}
              className="lab_headera_logo"
              alt=""
              style={{ maxWidth: "30%" }}
            />
            <img
              src={abc_logo}
              alt=""
              className=""
              style={{ maxWidth: "30%" }}
            />
          </section>
        </div>
      </div>
      <div className="mt-4">
        <Prize />
        <Banner2 />
      </div>
      <div
        style={{ backgroundImage: `url(${bgImg2})` }}
        className="banner_bg_control_three"
      >
        <Banner3 />
        <Banner4 />
        <Banner5 />
        <Audience />
        <Countries />
        <Join />
        <Program />
        {/* <OurTraining /> */}
      </div>
      {/* <HeaderaFooter/> */}
    </>
  );
}
