import React from 'react'
import ReasonThreeImg from '../../assets/images/homepage_assets/reason_img_2.webp'
import BgImg from '../../assets/images/our-solution/solution_banner.png'

export default function SolutionDetails() {
  return (
    <section className="solution_details">
      <div style={{ backgroundImage: `url(${BgImg})` }}>
        <div className="solutn_numbers">
          <div>
            <h4>12+</h4>
            <h6>NFT Projects</h6>
          </div>
          <div>
            <h4>180+</h4>
            <h6>Community Members</h6>
          </div>
          <div>
            <h4>300+</h4>
            <h6>Total Active Users</h6>
          </div>
        </div>
        <div className="solutn_underlay">
          <div className="blue_underlay"></div>
          <div className="lemon_underlay"></div>
        </div>
      </div>
      <div>
        <div>
          <h6>Our Mission</h6>
          <p>
            Accentuate the pan-african blockchain revolution through training
            10,000 blockchain developers in the next 5 years
          </p>
        </div>
        <div>
          <h6>Our Vision</h6>
          <p>
            ABC to be a pan-african leader in blockchain development across
            multiple chains and create a resource pool of talent to be the home
            of blockchain.
          </p>
        </div>
      </div>
      <div>
        <div style={{ backgroundImage: `url(${ReasonThreeImg})` }}></div>
        <div>
          <h4>New to Web 3.0? Don't worry, we'll show you around.</h4>
          <p>
            The Africa Blockchain Center is an independent capacity building &
            research centre focused on blockchain technology. We bring together
            Africa’s top industry leaders, academics, policy-makers,
            entrepreneurs and researchers to undertake ground-breaking research
            on blockchain technology and accentuate the pan-African blockchain
            revolution through training 10,000 blockchain developers by 2027. We
            focus on the strategic implications of blockchain technology on the
            developers, businesses, governments and society in Africa.
          </p>
        </div>
      </div>
    </section>
  )
}
