import React from 'react'
import ReasonOneImg from '../../assets/images/homepage_assets/reason_img_1.webp'
import ReasonTwoImg from '../../assets/images/homepage_assets/reason_img_2.webp'
import ReasonThreeImg from '../../assets/images/homepage_assets/reason_img_3.webp'
import ReasonFourImg from '../../assets/images/homepage_assets/reason_img_4.webp'
import ReasonFiveImg from '../../assets/images/homepage_assets/reason_img_5.webp'
import Arrow from '../../assets/svg/ArrowRightTwo.svg'

export default function Reasons() {
  return (
    <section style={{ paddingTop: 100 }}>
      <h3 className="text-center reasons_header">
        Reasons why we are <br /> your top choice
      </h3>
      <p className="text-center reasons_sub_header">
        Africa Blockchain Center aims to build a blockchain ecosystem in Africa
        that breeds highly skilled blockchain <br />
        engineers who will utilize every facet of the technology in building
        highly robust applications in Africa that will evolve
        <br /> society and the world as a whole as well as create demand for
        blockchain in Africa
      </p>
      <div className="reasons_img_wrapper p-5">
        <div className="containers">
          <img src={ReasonOneImg} alt="" className="reasons_images" />
          <div className="d-flex align-items-center mt-3">
            <div>
              <img src={Arrow} alt="" />
            </div>
            <div className="ps-3">
              <h3 className="reasons_section_headers">Capacity Training</h3>
              <p className="reasons_section_p">
                We actively engage in training blockchain engineers to build
                blockchain development capacity.
              </p>
            </div>
          </div>
        </div>
        <div className="containers">
          <img
            src={ReasonTwoImg}
            alt=""
            style={{ marginBottom: 32 }}
            loading="lazy"
            className="reasons_images"
          />
          <img
            src={ReasonThreeImg}
            alt=""
            loading="lazy"
            className="reasons_images"
          />

          <div className="d-flex align-items-center mt-3">
            <div>
              <img src={Arrow} alt="" loading="lazy" />
            </div>
            <div className="ps-3">
              <h3 className="reasons_section_headers">
                Blockchain Consultancy
              </h3>
              <p className="reasons_section_p">
                ABC aims to create a blockchain consulting arm that provides a
                360 support approach to organizations including advisory,
                architecting, programming and developing and deployment of
                blockchain solutions.
              </p>
            </div>
          </div>
        </div>
        <div className="containers">
          <div className="d-flex align-items-center">
            <div>
              <img src={Arrow} alt="" loading="lazy" />
            </div>
            <div className="ps-3">
              <h3 className="reasons_section_headers">
                Research & <br /> Development
              </h3>
              <p className="reasons_section_p">
                We Conduct Blockchain R&D for key industries with partners and
                inhouse ideation, incubate blockchain start-ups and launch an
                ABC Chain
              </p>
            </div>
          </div>

          <img
            src={ReasonFourImg}
            alt=""
            srcset=""
            style={{ marginBottom: 32 }}
            className="reasons_images"
          />
          <img
            src={ReasonFiveImg}
            alt=""
            loading="lazy"
            className="reasons_images"
          />
        </div>
      </div>
    </section>
  )
}
