import React from 'react'
import Navigation from '../../components/Navigation'
import BgImg from '../../assets/images/bg_1.png'
import Banner from '../../components/careers/Banner'
import AbcBenefit from '../../components/careers/AbcBenefit'
import BtmBanner from '../../components/careers/BtmBanner'

export default function Careers() {
  return (
    <div
      style={{
        backgroundImage: `url(${BgImg})`,
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Navigation />
      <Banner />
      <AbcBenefit />
      <BtmBanner />
    </div>
  )
}
