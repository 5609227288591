import React, { useState } from 'react'
import '../../assets/stylings/SummitStyle.css'
import { Link } from 'react-router-dom'
//
import bgImg from '../../assets/images/homepage_assets/bg1.webp'
import bgImg2 from '../../assets/images/homepage_assets/middle_bg.webp'

import NairobiImg from '../../assets/images/summit/venues/nairobi.png'
import Lusaka from '../../assets/images/summit/venues/Lusaka.png'
import CapeTown from '../../assets/images/summit/venues/CapeTown.png'
import Abuja from '../../assets/images/summit/venues/Abuja.png'
import Tz from '../../assets/images/summit/venues/Tz.png'
import Register from '../../assets/images/summit/venues/register.png'
import BgBlue from '../../assets/images/summit/bg_blue.png'
import Namibia from '../../assets/images/summit/namibia.jpeg'

// images
import Logo from '../../assets/images/abc_logo_white.png'
import BannerImg from '../../assets/images/events/event_banner.png'
import BannerBottom from '../../assets/images/summit/bg.png'
import JohnKamara from '../../assets/images/summit/john_kamara.png'
import Ray from '../../assets/images/summit/ray.png'
import BannerTwo from '../../assets/images/summit/banner_two.png'
import Building from '../../assets/images/summit/building.png'
import Nixon from '../../assets/images/summit/nixon-mageka-gecheo.png'
import Kenji from '../../assets/images/summit/Kenji_Sasaki.png'
import Gilbert from '../../assets/images/summit/Gilbert.png'
import SolutionInput, {
  SolutionDropDown,
} from '../../components/our-solutions/SolutionInput'
import { FiX } from 'react-icons/fi'
import SummitBtn from '../../components/summit/SummitBtn'
import Sandeep from '../../assets/images/summit/Sandeep.png'
import Yele from '../../assets/images/summit/yele.png'
import Michelle from '../../assets/images/summit/michelle.png'
import Part from '../../assets/images/summit/part.png'
import phone from '../../assets/svg/phone.png'
import Navigation from '../../components/Navigation'
import SubmitBanner from '../../components/summit/SubmitBanner'
import { LinkButton } from '../../components/Button'
import { HttpWithFetch, PostRequest } from '../../assets/utils/http'

export default function Summit() {
  let [company, setCompany] = useState('')
  let [industry, setIndustry] = useState('')
  // let [individualName, setIndividualName] = useState('')
  let [phoneNumber, setPhone] = useState('')
  let [designation, setDesignation] = useState('')
  let [interest, setInterest] = useState('')
  let [email, setEmail] = useState('')
  let [date, setDate] = useState('')
  let [summitName, setSummitName] = useState('')
  let [expectation, setExpectation] = useState('')

  let [firstName, setFirstName] = useState('')
  let [lastName, setLastName] = useState('')

  let [notification, setNotification] = useState('')

  const submitSummit = async (e) => {
    e.preventDefault()
    setNotification('Submitting ...')

    if (
      !company ||
      !industry ||
      !firstName ||
      !lastName ||
      !phoneNumber ||
      !designation ||
      !interest ||
      !expectation ||
      !email
    ) {
      setNotification('All Fields Must Be Filled!')
      return
    }

    let data = {
      company,
      industry,
      individual_name: `${firstName} ${lastName}`,
      phone_number: phoneNumber,
      designation,
      interest,
      date: '2023-03-01',
      summit_name: "ABC Leader's summit : BLOCKCHAINIZEafrica24",
      expectation,
      email,
    }

    // let summitRequest = await HttpWithFetch(data, 'summits/create', 'POST')

    let summitRequest = await PostRequest('summits/create', data)

    if (summitRequest.message === 'success') {
      setNotification('Form Submitted Successfully')
    } else {
      setNotification('Internal Server Error')
    }
  }

  let eventDetsText = [
    {
      id: 1,
      init: '50+',
      details: 'Leaders of Economy',
    },
    {
      id: 2,
      init: '20+',
      details: 'Master Classes',
    },
    {
      id: 3,
      init: '10+',
      details: 'Founders of Leading Businesses',
    },
    {
      id: 4,
      init: '5',
      details: 'Countries in Africa',
    },
  ]

  let schedule = [
    {
      time: '8:00 - 8:45 am',
      desc: 'Arrival of guests, Breakfast, Exhibitions',
    },
    {
      time: '9:00 - 9:10 am',
      desc:
        'Welcome & Introduction (GoH, Chairman, Speakers, Guest) + Summit introduction.  Mc: Fareed Khimani',
    },
    {
      time: '9:10 - 9:25 am',
      desc: 'Keynote Speech by Chairman of Event',
    },
    {
      time: '9:25 - 9:45 am',
      desc: 'Keynote Speech  ',
    },
    {
      time: '9:45 - 10:05 am',
      desc: 'Keynote Speech : Kenji Sasaki',
    },
    {
      time: '10:05 - 10:25 am ',
      desc: 'Keynote speech : John Kamara ',
    },
    {
      time: '10:25 - 10:35 am',
      desc: 'Tea / Bathroom Break',
    },
    {
      time: '10:35 - 10:45am',
      desc: 'Introduction (Masterclass) : MC: Fareed Khimani',
    },
    {
      time: '10:45 - 11:30 am',
      desc: 'Masterclass – Blockchain technology demystification : John Kamara',
    },
    {
      time: '11:30 - 11:35 am',
      desc: 'Introduction (Pre-Panel)  : MC: Fareed Khimani',
    },
    {
      time: '11:35 - 12:05 pm',
      desc: 'Masterclass - BFSI / Speaker : Dr. Gilbert Saggia',
    },
    {
      time: '12:05 - 12:10 pm',
      desc: 'Introduction (Pre-Panel) / Mc. Fareed Khimani',
    },
    {
      time: '12:10 - 12:40pm',
      desc: 'Masterclass– Marketing / Speaker: Joel Rao',
    },
  ]

  let scheduleII = [
    {
      time: '12:40 - 1:55 pm',
      desc: 'Lunch Break',
    },
    {
      time: '2:00 - 2:05 pm',
      desc: 'Introduction (Pre-Panel) / MC: Fareed Khimani',
    },
    {
      time: '2:05 - 2:35pm',
      desc:
        'Masterclass- Climate, Agric, Logistics, Energy / Speaker : Nixon Gecheo',
    },
    {
      time: '2:35 - 2:45 pm',
      desc: 'ABC Initiative Showcase / Morris',
    },
    {
      time: '2:45 - 2:50 pm',
      desc: 'Introduction (Pre-Panel) /  MC: Fareed Khimani',
    },
    {
      time: '2:50 - 3:20 pm ',
      desc:
        'Panel Discussion – Government, Regulation, Procurement, Education / Speaker : Kefa Ngoiri',
    },
    {
      time: '3:20 - 3:25 pm ',
      desc: 'Introduction (Pre-Panel) / MC: Fareed Khimani',
    },
    {
      time: '3:25 – 3:55 pm',
      desc: 'Panel Discussion (Health) / Speaker: Dr. Ron',
    },
    {
      time: '3:55 - 4:10 pm ',
      desc: 'Tea Break',
    },
    {
      time: '4:10 - 4:15 pm ',
      desc: 'Introduction(Pre-Panel) / MC: Fareed Khimani',
    },
    {
      time: '4:15 – 4:45 pm',
      desc: 'Panel Discussion (Climate change and carbon credits)',
    },
    {
      time: '4:45 - 5:00 pm',
      desc: 'Closing Keynote + Closing remarks ',
    },
    {
      time: '5:10pm - 7:30 pm',
      desc: 'Cocktail Networking + Gift Hampers',
    },
  ]

  let discussionI = [
    'Banking & Finance',
    'Supply chain & logistics Energy Software Development',
    'Big Tech',
    'E-Commerce Government',
    'Music gaming & Entertainment AI Insurance',
    'Retail',
    'Others',
  ]

  let discussionII = [
    'Healthcare',
    'Cloud computing',
    'Cyber Security',
    'Telecom & Communication Education',
    'Real Estate',
    'Database management',
    'Data Science',
    'Consulting',
  ]

  let otherCountries = [
    {
      id: 1,
      country: 'South Africa',
      desc:
        'Africa Blockchain Center aims to lead efforts purely focused on capacity building in blockchain technology ',
    },
    {
      id: 2,
      country: 'Namibia',
      desc:
        'Africa Blockchain Center aims to lead efforts purely focused on capacity building in blockchain technology ',
    },
    {
      id: 3,
      country: 'Nigeria',
      desc:
        'Africa Blockchain Center aims to lead efforts purely focused on capacity building in blockchain technology ',
    },
  ]

  let venueI = [
    {
      month: 'JULY',
      day: '12',
      venue: 'Vila Rosa Kempinski',
      country: 'Nairobi, Kenya',
      location: 'Nairobi',
      img: NairobiImg,
    },
    {
      month: 'SEPT',
      day: '--',
      venue: 'Vila Rosa Kempinski',
      country: 'Lusaka, Zambia',
      location: 'Lusaka',
      img: Lusaka,
    },
    {
      month: 'AUG',
      day: '17',
      venue: 'Vila Rosa Kempinski',
      country: 'Cape Town, SA',
      location: 'Cape Town',
      img: CapeTown,
    },
  ]

  let venueII = [
    {
      month: 'AUG',
      day: '15',
      venue: 'Vila Mashar',
      country: 'Johannesburg, SA',
      location: 'Johannesburg',
      img: CapeTown,
    },
  ]

  let venueIII = [
    {
      month: 'SEPT',
      day: '06',
      venue: 'Vila Rosa Kempinski',
      country: 'Lagos Nigeria',
      location: 'Lagos',
      img: Abuja,
    },
    {
      month: 'OCT',
      day: '11',
      venue: '--',
      country: 'Windhoek Namibia',
      location: 'Windhoek',
      img: Namibia,
    },
    {
      month: 'OCT',
      day: '18',
      venue: 'Vila Rosa Kempinski',
      country: 'Dar es Salaam, TZ',
      location: 'Dar es Salaam',
      img: Tz,
    },
  ]

  return (
    <>
      <div
        style={{ backgroundImage: `url(${bgImg})` }}
        className="pt-2 pb-5 banner_bg_control"
      >
        <Navigation />
        <SubmitBanner />

        <div className="">
          <section className="d-flex flex-column justify-content-center align-items-center">
            <h3 className="text-center summit_subHeaderII FONT_II_BOLD">
              If you want to sponsor or partner? Feel free to reach out.
            </h3>
            <p
              className="text-center summit_subTextII FONT_DM_SANS"
              style={{ fontSize: 16 }}
            >
              If you wish to participate, partner or be a prime sponsor on the
              Blockchain for leaders summit, which will bring together 100+ web2
              and web3 leaders, kindly reach out to us and we will dearly
              consider your name for inclusion.
            </p>
          </section>
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${bgImg2})` }}
        className="banner_bg_control_three"
      >
        <div className="summit_details">
          <div>
            <h6 className="summit_detail_header FONT_FAM_II">
              <strong>One</strong> Event <br />
              <strong>Multiple</strong> sessions
            </h6>

            <div className="summit_inner_det">
              <p>
                This is where you belong,
                <br /> lets blockchanize
              </p>
            </div>
            <div className="selling_pt">
              {eventDetsText.map((_) => (
                <div key={_.id}>
                  <p className="FONT_FAM_II">{_.init}</p>
                  <p className="FONT_DM_SANS">{_.details}</p>
                </div>
              ))}
            </div>
          </div>
          <div>
            <p className="FONT_DM_SANS selling_pts_summary">
              The Blockchain for leaders summit brings together 100+ web 2 and
              web3 leaders, technology professionals, government oﬃcials,
              business leaders, change makers, tech entrepreneurs and senior
              practitioners for one day of unparalleled blockchain discovery and
              insight sharing to build the blocks for blockchain transformation
              in the continent.
            </p>

            <p className="FONT_DM_SANS selling_pts_summary">
              The participants will have the opportunity to learn from and
              network with 100+ CEO/CTO/DGs fromAfrica’s top companies,
              government institutions, NGOs and tech ecosystem.
            </p>

            {/* <div className="mt-5">
            <SummitBtn title="Let's Chat Here" href="/" />
          </div> */}
          </div>
        </div>
      </div>
      <div className="explore_summit">
        <div>
          <h5 className="explore_header FONT_DM_SANS">Explore the summit</h5>
          <h3 className="explore_sub_header FONT_II_BOLD">
            About the <br />
            ABC Leaders Summit
          </h3>

          <p className="explore_content FONT_DM_SANS">
            The summit aims to connect global blockchain leaders and technology
            players in this space including emerging startups – with regional
            businesses, government authorities, IT leaders, tech entrepreneurs,
            investors and blockchain developers. The summit will feature
            enterprise use-cases, government use cases, inspirational keynotes,
            panel discussions, tech-talks, blockchain exhibition, startup pitch
            competitions, and a host of networking opportunities.
          </p>

          <img src={BannerBottom} alt="" srcset="" />
        </div>
      </div>
      <div className="key_note_speakers_container">
        <div>
          {/* header */}
          <div className="speaker_wrapper">
            <div>
              <h6 className="speaker_header FONT_DM_SANS">
                Meet the voices of the event
              </h6>
              <h6 className="speaker_header_II FONT_II_BOLD">
                KeySpeakers
                <br /> in the Summit
              </h6>
            </div>
            <div>
              <p className="speaker_content">
                A chance to learn from and interact with renowned experts in the
                technology ﬁeld bringing together a wealth of knowledge and
                experience.
              </p>
              <p className="speaker_content">
                Meet some of our inspirational, highly-sought and captivating
                speakers in the summit event who have gained years of experience
                in the technological ﬁeld.
              </p>
            </div>
          </div>

          <div className="speaker_wrapper mt-5">
            <div>
              <img
                src={JohnKamara}
                alt="john kamara"
                style={{ borderBottom: `15px #BDCA2F solid` }}
              />
            </div>
            <div className="speakers_profile">
              <h3 className="FONT_II_BOLD">John Kamara</h3>
              <div>
                <p className="FONT_FAM_II">
                  <span>Founder,</span> Adanian Labs and Afyarekod
                  <br />
                  <span>Chairman,</span> The Africa Blockchain Center
                  <br />
                </p>
              </div>
              <section>
                <p className="FONT_DM_SANS">
                  John is one of Africa’s leading experts on how businesses can
                  leverage key technology trends, transform organisations and
                  drive competitive advantage for impact in industries including
                  finance, agriculture, health, education, gaming and startup
                  enterprises. John is actively involved and heavily invested in
                  new technologies focusing on IoT (Internet of Things), AI
                  (Artificial Intelligence), Blockchain and Crowdfunding. Having
                  worked in market growth leadership roles for global technology
                  companies at Google, Conduit, eir Ireland, Digicel, BMC
                  Software and others, John is committed to seeing technology
                  play a critical role catapulting Africa into the Fourth
                  Industrial Revolution while creating critical mass impact.
                </p>
              </section>
            </div>
          </div>

          <div className="speaker_wrapper mt-5">
            <div className="speakers_profile">
              <h3 className="FONT_II_BOLD">Kenji Sasaki</h3>
              <div>
                <p className="FONT_FAM_II">
                  <span>CEO</span>
                  <br />
                  <span>Chymia Consulting HK</span>
                  <br />
                  chymia.io
                </p>
              </div>
              <section>
                <p className="FONT_DM_SANS">
                  Chymia Consulting HK, a Hong Kong based blockchain consulting
                  group that helps new platforms enter the market; and Corporate
                  Officer at Emurgo, a sister organization of the Cardano
                  project that supports for expansion and adoption of the
                  Cardano blockchain ecosystem across the globe. Mr. Kenji
                  Sasaki is a visionary with successful entrepreneurial
                  achievements to his name within the Asian market. With his
                  position as an advisor to the PumaPay platform, he brings with
                  him a wealth of experience and insight into the blockchain
                  sector that would allow PumaPay to expand and reach out to
                  investors and users, making it a success.
                </p>
              </section>
            </div>
            <div>
              <img
                src={Kenji}
                alt="Kenji"
                style={{ borderBottom: `15px #BDCA2F solid` }}
              />
            </div>
          </div>

          <div className="speaker_wrapper mt-5">
            <div>
              <img
                src={Nixon}
                alt="Nixon Gecheo"
                style={{ borderBottom: `15px #BDCA2F solid` }}
              />
            </div>
            <div className="speakers_profile">
              <h3 className="FONT_II_BOLD">Nixon Gecheo</h3>
              <div>
                <p className="FONT_FAM_II">
                  <span>He is a technology, data and public policy expert</span>
                  <br />
                  www.agra.org
                </p>
              </div>
              <section>
                <p className="FONT_DM_SANS">
                  Nixon Mageka Gecheo is the immediate former ICT and Media
                  Advisor to the Cabinet Secretary of Agriculture. He is a
                  governance and ICT4D consultant and currently a Member of the
                  Universal Service Advisory Council of the Communication
                  Authority of Kenya, Advising on the use of Universal Service
                  Fund in Spreading ICTs to the Underserved and un- served areas
                  of the Republic of Kenya. Nixon also serves as a council
                  member of the Turkana University College. Prior to being a
                  consultant, Mageka worked for 10 years in Public Sector mainly
                  in Public sector reforms and the role of information
                  technology in organizations. Mageka worked as a Chief ICT
                  officer for the Commission on Administrative Justice also
                  known as the Ombudsman.
                </p>
              </section>
            </div>
          </div>

          <div className="speaker_wrapper mt-5">
            <div className="speakers_profile">
              <h3 className="FONT_II_BOLD">Gilbert Saggia </h3>
              <div>
                <p className="FONT_FAM_II">
                  Founder
                  <br />
                  Edge Cards
                  <br />
                  <span>edge.cards</span>
                </p>
              </div>
              <section>
                <p className="FONT_DM_SANS">
                  Executive business professional with tech industry leadership
                  and operation management, strategic business execution and
                  managing diverse teams for 20+ years. Serial tech entrepreneur
                  focused on 10x scale verticals.Specialties:Tech Industry
                  Entrepreurship, Leadership, Strategic Planning and
                  (Re)Building Organizations, Value Chain Creation and
                  Management, Public Sector Policy Advisory in Technology,
                  Emerging Markets.Experience and broad talent in transforming
                  scale companies and teams to be all-rounded high performers
                </p>
              </section>
            </div>
            <div>
              <img
                src={Gilbert}
                alt="Gilbert Saggia "
                style={{ borderBottom: `15px #BDCA2F solid` }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center mt-5 speaker_reason">
        <div style={{ width: '50%', border: `5px solid #BDCA2F` }}></div>
        <h3 className="FONT_FAM_II">
          Reasons why you will miss
          <br /> out if you fail to show up
        </h3>
        <p className="FONT_DM_SANS">
          In this event, you will get a thorough understanding of blockchain
          technology, experience the world's latest groundbreaking blockchain
          technologies, learn about the industry trends and innovations, get
          inspiration from expert speakers and engage in open discussions,
          connect with like minded professionals from industry and government.
          You get to also become a member of a unique and rapidly growing
          Pan-African blockchain network.
        </p>
      </div>
      <div
        className="reasons_summit"
        style={{ backgroundImage: `url(${BgBlue})` }}
      >
        {/* <div className="row">
          <div className="col-md-6">
            <h6 className="text-white FONT_DM_SANS">The planning</h6>
            <h3
              style={{ color: '#BDCA2F', fontSize: 34 }}
              className="FONT_FAM_II"
            >
              Explore the
              <br /> schedule
            </h3>
          </div>
          <div className="col-md-6">
            <p  style={{ color: '#34368A' }}>
              A chance to learn from and interact with renowned experts in the
              technology ﬁeld bringing together a wealth of knowledge and
              experience. Meet some of our inspirational, highly-sought and
              captivating speakers in the summit event who have gained years of
              experience in the technological ﬁeld.
            </p>
          </div>
        </div> */}

        <div className="event_schedule">
          <div>
            {schedule.map((_) => (
              <div>
                <h3 className="FONT_II">{_.time}</h3>
                <p>{_.desc}</p>
              </div>
            ))}
          </div>
          <div>
            {scheduleII.map((_) => (
              <div>
                <h3 className="FONT_II">{_.time}</h3>
                <p>{_.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="summit_discussion_pts">
        <h4 className="FONT_DM_SANS">All expected sectors</h4>
        <h2 className="FONT_II_BOLD">Main areas of discussion</h2>

        <p className="FONT_DM_SANS">
          Mark your presence and scale across numerous sectors.
        </p>

        <div className="row mb-5">
          <div className="col-md-6">
            <ul className="summit_dis_list">
              {discussionI.map((title, id) => (
                <li key={id + 1}>{title}</li>
              ))}
            </ul>
          </div>
          <div className="col-md-6">
            <ul className="summit_dis_list">
              {discussionII.map((title, id) => (
                <li key={id + 1}>{title}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="float_block">
        <div className="d-flex flex-column justify-content-center">
          <h3 className="text-center">Where will it all happen?</h3>
          <h4 className="FONT_FAM_II text-center">Happening across Africa</h4>
          <p className="FONT_DM_SANS">
            The event will at take place at Villa Rosa Kempinski in Nairobi,
            Kenya. The venue provides a great setting for networking, connecting
            and information exchange between web2 and web3 ecosystems.. The
            event will bring in top tier experts, 100+ C-suite individuals and
            Leaders from top industries to discuss and build capacity and
            knowledge around blockchain technology and how it can empower
            businesses through solution-driven thinking.
          </p>
        </div>
        <div className="row mt-5">
          <div className="col-md-4">
            {venueI.map((_) => (
              <div className="_submit_venue">
                <div
                  className="_summit_img"
                  style={{ backgroundImage: `url(${_.img})` }}
                ></div>
                <div className="_summit_venue_details">
                  <div className="d-flex flex-row">
                    <div
                      style={{ width: '30%' }}
                      className="_summit_timig FONT_DM_SANS flex-center"
                    >
                      {_.month} <br />
                      <span>{_.day}</span>
                    </div>
                    <div style={{ width: '70%' }} className=" flex-center">
                      <h6 className="FONT_DM_SANS happening">Happening in</h6>
                      <h6 className="FONT_DM_SANS country_text">{_.country}</h6>
                    </div>
                  </div>
                  <div>
                    {_.location !== 'Nairobi' ? null : (
                      <>
                        <h6 className="venue_Descp">The Venue</h6>
                        <h3 className="_venue venue_Descp">{_.venue}</h3>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-md-4">
            <div className="middleVenue">
              {venueII.map((_) => (
                <div className="_submit_venue">
                  <div
                    className="_summit_img"
                    style={{ backgroundImage: `url(${_.img})` }}
                  ></div>
                  <div className="_summit_venue_details">
                    <div className="d-flex flex-row">
                      <div
                        style={{ width: '30%' }}
                        className="_summit_timig FONT_DM_SANS flex-center"
                      >
                        {_.month} <br />
                        <span>{_.day}</span>
                      </div>
                      <div style={{ width: '70%' }} className=" flex-center">
                        <h6 className="FONT_DM_SANS happening">Happening in</h6>
                        <h6 className="FONT_DM_SANS country_text">
                          {_.country}
                        </h6>
                      </div>
                    </div>
                    <div>
                      {_.location !== 'Nairobi' ? null : (
                        <>
                          <h6 className="venue_Descp">The Venue</h6>
                          <h3 className="_venue venue_Descp">{_.venue}</h3>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              <div className="_submit_venue" style={{ background: '#92C020' }}>
                <div
                  className="_summit_img"
                  style={{ backgroundImage: `url(${Register})` }}
                ></div>
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    type="button"
                    className="summitCustomBtn"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    style={{ border: 0 }}
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {venueIII.map((_) => (
              <div className="_submit_venue">
                <div
                  className="_summit_img"
                  style={{ backgroundImage: `url(${_.img})` }}
                ></div>
                <div className="_summit_venue_details">
                  <div className="d-flex flex-row">
                    <div
                      style={{ width: '30%' }}
                      className="_summit_timig FONT_DM_SANS flex-center"
                    >
                      {_.month} <br />
                      <span>{_.day}</span>
                    </div>
                    <div style={{ width: '70%' }} className=" flex-center">
                      <h6 className="FONT_DM_SANS happening">Happening in</h6>
                      <h6 className="FONT_DM_SANS country_text">{_.country}</h6>
                    </div>
                  </div>
                  <div>
                    {_.location !== 'Nairobi' ? null : (
                      <>
                        <h6 className="venue_Descp">The Venue</h6>
                        <h3 className="_venue venue_Descp">{_.venue}</h3>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="summit_footer">
        <div className="floating_discussion">
          <div className="d-flex flex-column justify-content-center">
            <h3 className="text-center">Where will it all happen?</h3>
            <h4 className="FONT_FAM_II text-center">Happening across Africa</h4>
            <p className="FONT_DM_SANS">
              The event will at take place at Villa Rosa Kempinski in Nairobi,
              Kenya. The venue provides a great setting for networking,
              connecting and information exchange between web2 and web3
              ecosystems.. The event will bring in top tier experts, 100+
              C-suite individuals and Leaders from top industries to discuss and
              build capacity and knowledge around blockchain technology and how
              it can empower businesses through solution-driven thinking.
            </p>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              {venueI.map((_) => (
                <div className="_submit_venue">
                  <div
                    className="_summit_img"
                    style={{ backgroundImage: `url(${_.img})` }}
                  ></div>
                  <div className="_summit_venue_details">
                    <div className="d-flex flex-row">
                      <div
                        style={{ width: '30%' }}
                        className="_summit_timig FONT_DM_SANS flex-center"
                      >
                        {_.month} <br />
                        <span>{_.day}</span>
                      </div>
                      <div style={{ width: '70%' }} className=" flex-center">
                        <h6 className="FONT_DM_SANS happening">Happening in</h6>
                        <h6 className="FONT_DM_SANS country_text">
                          {_.country}
                        </h6>
                      </div>
                    </div>
                    <div>
                      {_.location !== 'Nairobi' ? null : (
                        <>
                          <h6 className="venue_Descp">The Venue</h6>
                          <h3 className="_venue venue_Descp">{_.venue}</h3>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-4">
              <div className="middleVenue">
                {venueII.map((_) => (
                  <div className="_submit_venue">
                    <div
                      className="_summit_img"
                      style={{ backgroundImage: `url(${_.img})` }}
                    ></div>
                    <div className="_summit_venue_details">
                      <div className="d-flex flex-row">
                        <div
                          style={{ width: '30%' }}
                          className="_summit_timig FONT_DM_SANS flex-center"
                        >
                          {_.month} <br />
                          <span>{_.day}</span>
                        </div>
                        <div style={{ width: '70%' }} className=" flex-center">
                          <h6 className="FONT_DM_SANS happening">
                            Happening in
                          </h6>
                          <h6 className="FONT_DM_SANS country_text">
                            {_.country}
                          </h6>
                        </div>
                      </div>
                      <div>
                        {_.location !== 'Nairobi' ? null : (
                          <>
                            <h6 className="venue_Descp">The Venue</h6>
                            <h3 className="_venue venue_Descp">{_.venue}</h3>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

                <div
                  className="_submit_venue"
                  style={{ background: '#92C020' }}
                >
                  <div
                    className="_summit_img"
                    style={{ backgroundImage: `url(${Register})` }}
                  ></div>
                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      type="button"
                      className="summitCustomBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      style={{ border: 0 }}
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {venueIII.map((_) => (
                <div className="_submit_venue">
                  <div
                    className="_summit_img"
                    style={{ backgroundImage: `url(${_.img})` }}
                  ></div>
                  <div className="_summit_venue_details">
                    <div className="d-flex flex-row">
                      <div
                        style={{ width: '30%' }}
                        className="_summit_timig FONT_DM_SANS flex-center"
                      >
                        {_.month} <br />
                        <span>{_.day}</span>
                      </div>
                      <div style={{ width: '70%' }} className=" flex-center">
                        <h6 className="FONT_DM_SANS happening">Happening in</h6>
                        <h6 className="FONT_DM_SANS country_text">
                          {_.country}
                        </h6>
                      </div>
                    </div>
                    <div>
                      {_.location !== 'Nairobi' ? null : (
                        <>
                          <h6 className="venue_Descp">The Venue</h6>
                          <h3 className="_venue venue_Descp">{_.venue}</h3>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl  modal-dialog-centered  modal-dialog-scrollable">
          <div class="modal-content">
            <div className="close_btn_modal" data-bs-dismiss="modal">
              <FiX color="#fff" size={25} />
            </div>
            <div class="modal-body">
              <form>
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 order-5 order-md-1 _form_section">
                      <div className="row">
                        <div className="col-md-6">
                          <SolutionInput
                            placeholder="First Name"
                            value={firstName}
                            setValue={setFirstName}
                          />
                        </div>
                        <div className="col-md-6">
                          <SolutionInput
                            placeholder="Last Name"
                            value={lastName}
                            setValue={setLastName}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <SolutionInput
                            placeholder="Company/Institution"
                            value={company}
                            setValue={setCompany}
                          />
                        </div>
                        <div className="col-md-6">
                          <SolutionInput
                            placeholder="E-Mail"
                            value={email}
                            setValue={setEmail}
                          />
                        </div>
                      </div>
                      <SolutionInput
                        placeholder="Industry"
                        value={industry}
                        setValue={setIndustry}
                      />
                      <SolutionInput
                        placeholder="Phone Number"
                        value={phoneNumber}
                        setValue={setPhone}
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <SolutionDropDown
                            placeholder="Designation"
                            value={designation}
                            setValue={setDesignation}
                          >
                            <option value="male">Mr.</option>
                            <option value="female">Mrs.</option>
                          </SolutionDropDown>
                        </div>
                        <div className="col-md-8">
                          <SolutionDropDown
                            placeholder="Select Entity"
                            value={null}
                            setValue={() => null}
                          >
                            <option value="student">Student</option>
                            <option value="mentor">Mentor</option>
                            <option value="trainer">Trainer</option>
                          </SolutionDropDown>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <SolutionInput
                            placeholder="Interest"
                            value={interest}
                            setValue={setInterest}
                          />
                        </div>
                        <div className="col-md-8">
                          <SolutionInput
                            placeholder="Expectation"
                            value={expectation}
                            setValue={setExpectation}
                          />
                        </div>
                      </div>

                      {notification && (
                        <div className="alert alert-secondary">
                          {notification}
                        </div>
                      )}

                      <div className="_modal_btn_cont">
                        <button onClick={submitSummit} className="_modal_btn">
                          Submit
                        </button>
                      </div>
                    </div>
                    <div className="col-md-4 order-1 order-md-5 _form_section">
                      <h3 className="FONT_DM_SANS _form_section_header">
                        Register to gain access to world class resources that
                        will push you to the next level.
                      </h3>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* <div class="modal-footer"> */}
            {/* <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  )
}
