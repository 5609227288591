import React from 'react'
import bgImg from '../../assets/images/bg_4.png'
import Navigation from '../Navigation'

export default function BannerThree({ body }) {
  return (
    <div
      style={{ backgroundImage: `url(${bgImg})`, height: 850 }}
      className="banner_bg_control"
    >
      <Navigation />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 600,
        }}
      >
        {body}
      </div>
    </div>
  )
}
