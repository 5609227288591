import React from 'react'
import FixedWidthWrapper from '../FixedWidthWrapper'
import PieLogo from '../../assets/svg/training/train1.svg'
import Jeff from '../../assets/images/about/team/jeff.png'
import Meave from '../../assets/images/about/team/Meave.jpeg'
import Morris from '../../assets/images/about/team/morris.png'
import Robert from '../../assets/images/about/team/robert.png'
import Pamela from '../../assets/images/about/team/pamela.jpg'
import {
  GrLinkedinOption,
  GrTwitter,
  GrInstagram,
  GrLinkedin,
} from 'react-icons/gr'

import HeadPhone from '../../assets/images/about/valueIcon/headphones.png'
import Board from '../../assets/images/about/valueIcon/board.png'
import Chart from '../../assets/images/about/valueIcon/chart.png'

export default function Heroes() {
  let sections = [
    {
      key: 1,
      number: '01',
      text: 'END-TO-END DEVELOPMENT FROM CONCEPT TO LAUNCH',
      img: HeadPhone,
    },
    {
      key: 2,
      number: '02',
      text: 'POST-LAUNCH TRANSFER, TRAINING AND SUPPORT',
      img: Chart,
    },
    {
      key: 3,
      number: '03',
      text: 'RESEARCH AND DEVELOPMENT KNOWLEDGE TRANSFER',
      img: Board,
    },
  ]

  let team = [
    // {
    //   id: 1,
    //   name: 'Jeff Mkungusi',
    //   role: 'CEO',
    //   linkedIn: '',
    //   IG: '',
    //   Twitter: '',
    //   img: Jeff,
    // },
    // {
    //   id: 2,
    //   name: 'Anderson Rioba',
    //   role: 'Knowledge Manager',
    //   linkedIn: '',
    //   IG: '',
    //   Twitter: '',
    //   img: Robert,
    // },
    // {
    //   id: 3,
    //   name: 'Meave Apondi',
    //   role: 'Community Manager',
    //   linkedIn: '',
    //   IG: '',
    //   Twitter: '',
    //   img: Meave,
    // },
    // {
    //   id: 4,
    //   name: 'Pamela Mwirigi',
    //   role: 'Projects Manager',
    //   linkedIn: 'https://www.linkedin.com/in/mukiri-mwirigi/',
    //   IG: '',
    //   Twitter: '',
    //   img: Pamela,
    // },
  ]
  return (
    <section className="heroes_section">
      <h3 className="text-center">
        Personalized decentralized
        <br /> execution
      </h3>
      <FixedWidthWrapper>
        <p>
          We can take care of every step – from concept, to coding, to
          after-launch support – or jump in when you need us. We keep you in the
          loop, and your results on our mind.
        </p>
      </FixedWidthWrapper>
      <div className="values_container">
        <div
          className="value_statment value_statment_heroes"
          style={{ borderBottom: 'none' }}
        >
          {sections.map((_) => (
            <div key={_.key}>
              <img src={_.img} alt="" />
              <h3>{_.number}</h3>
              <p>{_.text}</p>
            </div>
          ))}
        </div>
      </div>
      <section className="meet_the_team">
        {/* <h3 className="text-center">Meet The Heroes</h3> */}
        <FixedWidthWrapper>
          {/* <p>
            Over the years, we have built a truly reliable team that consists of
            bright, passionate, and brave professionals. The people who work
            with us have a deep wealth of knowledge and experience in their
            fields.
          </p> */}
        </FixedWidthWrapper>
        <div className="hero_member">
          {team.map((_) => (
            <div key={_.id}>
              <div
                style={{
                  backgroundImage: `url(${_.img})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className="team_imgs"
              ></div>
              <h4>{_.name}</h4>
              <p>{_.role}</p>
              {/*  */}
              <div className="heroes_socials_wrapper">
                <div>
                  <GrInstagram />
                </div>
                <div>
                  <GrLinkedin />
                </div>
                <div>
                  <GrTwitter />
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* <section className="team_testimony">
        <h3>
          Their commitment to collaborative efforts, innovative solutions, and
          swift execution sets ABC apart.“
        </h3>
        <div>
          <div></div>
          <div>
            <h4>
              Abiodun <br /> Mayowa
            </h4>
            <p>Security and Blockchain Consultant</p>
          </div>
        </div>
      </section> */}
    </section>
  )
}
