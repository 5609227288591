import React from 'react'

export default function CustomInput({ placeholder, label }) {
  return (
    <div style={{ marginBottom: 20, width: '100%' }}>
      <label
        htmlFor=""
        className="FONT_DM_SANS"
        style={{ color: '#fff', fontWeight: 'lighter' }}
      >
        {label}
      </label>
      <input
        placeholder={placeholder}
        className="FONT_DM_SANS"
        style={{
          height: 50,
          width: 'inherit',
          backgroundColor: '#343333',
          border: `1px solid #696969`,
          padding: `0px 25px`,
          color: '#696969',
          borderRadius: 4,
        }}
      />
    </div>
  )
}

export function CustomTextInput({ placeholder, label }) {
  return (
    <div style={{ marginBottom: 20, width: '100%' }}>
      <label
        htmlFor=""
        className="FONT_DM_SANS"
        style={{ color: '#fff', fontWeight: 'lighter' }}
      >
        {label}
      </label>

      <textarea
        style={{
          height: 150,
          width: 'inherit',
          backgroundColor: '#343333',
          border: `1px solid #696969`,
          padding: `20px 25px`,
          color: '#696969',
          borderRadius: 4,
        }}
        placeholder={placeholder}
      ></textarea>
    </div>
  )
}
