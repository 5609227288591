import React from 'react'
import Navigation from '../../components/Navigation'
import BG from '../../assets/images/footer/bg_top.png'
import MacImage from '../../assets/images/footer/mac_laptop.png'

import HeadPhone from '../../assets/svg/leverageIcons/headphone.svg'
import Chart from '../../assets/svg/leverageIcons/chart.svg'
import Rnd from '../../assets/svg/leverageIcons/rnd.svg'

export default function Inclusivity() {
  return (
    <>
      <div>
        <Navigation />
        <div className="container inclu_header">
          <h3 className="FONT_FAM_II">
            Help build the best tools for our employees.
          </h3>
          <h6 className="FONT_DM_SANS">Updated Feb 3, 2023</h6>
          <h5 className="FONT_DM_SANS">
            We're empowering creatives around the world to make a living doing
            what they love.
          </h5>
          <hr className="custom_hr" />
        </div>
        <div />
        <div className="container mb-5">
          <div className="row">
            <div className="col-md-6 inclu_details">
              <h6 className="FONT_DM_SANS">
                We're empowering creatives in Africa and around the world
              </h6>
              <p className="FONT_DM_SANS">
                Our mission is to provide support at every stage of a blockchain
                creative's journey; whether that's freelancing, selling their
                physical and digital NFTs, or working on blockchain for some of
                the world's leading design-forward companies.
              </p>
            </div>
            <div className="col-md-6">
              <img src={BG} alt="" style={{ width: '100%' }} />
            </div>
          </div>
        </div>

        <div className="container mb-5">
          <div className="row">
            <div className="col-md-6">
              <img src={BG} alt="" style={{ width: '100%' }} />
            </div>
            <div className="col-md-6 inclu_details">
              <h6 className="FONT_DM_SANS">
                A culture of communication, collaboration & connection.
              </h6>
              <p className="FONT_DM_SANS">
                We’ve been fully blockchain since day one, and are building a
                fully distributed team across Africa. We believe that creative
                collaboration can happen anywhere, and that working remotely
                across Africa shouldn’t have to mean sacrificing a sense of
                cohesion, community, and connection. We've seen that by
                combining thoughtful collaboration, frequent communication, and
                the freedom for people to be their authentic selves, you can do
                your best work and inspire others to do the same.
              </p>
            </div>
          </div>
        </div>

        <div className="container pt-5 inclu_sub_sec">
          <h3 className="text-center FONT_FAM_II">
            Our policies guide everything
          </h3>
          <div className="d-flex align-items-center justify-content-center mb-5">
            <p className="text-center FONT_DM_SANS">
              Our values and policies are part of who we are, what we believe
              in, what we aspire to be, and they are reflected in our actions.
              They are our commitment to one another, and the collective
              personality of our organisation. We always strive to lean into the
              fact that when properly practiced, sometimes our values will push
              us outside of our comfort zone.
            </p>
          </div>

          <div className="container mb-5">
            <div className="row">
              <div className="col-md-4 d-flex flex-column align-items-center inclu_policy">
                <img src={HeadPhone} alt="" srcset="" />
                <h5 className="FONT_DM_SANS">We are all owners</h5>
                <p className="FONT_DM_SANS">
                  Every day, we take the initiative and accept the
                  responsibility—with integrity and courage—to make Africa
                  Blockchain Center company better. We believe that raising the
                  bar is what we owe to each other. We are committed to pursuing
                  learning, innovation, and growth together to increase
                  blockchain adoption in Africa.
                </p>
              </div>
              <div className="col-md-4 d-flex flex-column align-items-center inclu_policy">
                <img src={Chart} alt="" srcset="" />
                <h5>Help customers succeed</h5>
                <p>
                  We come to work every day committed to driving success for our
                  customers. We create value for our community by building and
                  providing meaningful solutions to solve our customer's
                  problems and needs. We overcome obstacles, find creative
                  solutions and deliver exceptional results. We’re only
                  successful if our partners are.
                </p>
              </div>
              <div className="col-md-4 d-flex flex-column align-items-center inclu_policy">
                <img src={Rnd} alt="" srcset="" />
                <h5>Bring good vibes</h5>
                <p>
                  Our success is driven by our ability to build relationships.
                  We have the courage to foster trust, knowing that it will lead
                  to being able to be our authentic selves, and the ability to
                  communicate and collaborate more effectively together.
                  Paramount to maintaining this inclusive and creative culture
                  is respect for the opinions, ideas, and feelings of others.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="filler_bg"
          style={{ backgroundImage: `url(${MacImage})` }}
        ></div>
        <div className="perks">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-8">
                <h4 className="text-center text-white FONT_FAM_II">
                  Perks and Benefits
                </h4>
                <p className="text-white FONT_DM_SANS text-center">
                  Our benefits have been designed with our philosophy about
                  co-working in mind: that it’s the best way to support our team
                  in working where they feel most inspired, productive,
                  comfortable and creative. We’re committed to providing our
                  team with the freedom and flexibility that comes with working
                  within the office and remotely, and support our team by
                  providing everyone with the tools you need to do your best
                  work, in the work environment of your choice. While we offer
                  competitive and comprehensive benefits, lots of other
                  organisations can offer you those. We also offer a rarer
                  opportunity: The ability to have a significant impact on the
                  livelihoods and careers of our customers, working on a
                  blockchain platform that operates at (a quickly growing)
                  scale.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="encourage">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-8">
                <h4 className="text-center FONT_FAM_II">
                  We encourage socialism
                </h4>
                <p className="FONT_DM_SANS text-center">
                  While we offer competitive and comprehensive benefits to our
                  workers but we also encourage them to spread the adoption of
                  blockchain and create more awareness through our communities.
                  We have ABC communities on Telegram, WhatsApp and Discord. We
                  aim to be the leaders in Blockchain in Africa but we will need
                  our workers to be at the best of their game.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
