import React from 'react'
import { CustomBtn } from '../Button'
import FixedWidthWrapper from '../FixedWidthWrapper'

export default function BtmBanner() {
  return (
    <section className="btm_banner_cont">
      <FixedWidthWrapper>
        <h3 className="FONT_FAM_II btm_text">
          We are passionate about supporting the blockchain movement that
          currently improves the lives of hundreds of millions of people in
          Africa and worldwide.
        </h3>
      </FixedWidthWrapper>

      <CustomBtn btnClr="#fff" btnTitle="Learn More" textClr="#000" />
    </section>
  )
}
