import React from 'react'
import { Link } from 'react-router-dom'

export default function Button() {
  return <div>Button</div>
}

export function LinkButton({
  title,
  foreIcon,
  postIcon,
  route,
  textClr,
  btnClr,
  hasBorder = false,
}) {
  return (
    <Link
      to={route}
      style={{
        backgroundColor: btnClr,
        color: textClr,
        borderWidth: hasBorder ? 1.04 : 0,
      }}
      className="custom_button"
    >
      {title}
    </Link>
  )
}

export function AnchorLink({
  title,
  foreIcon,
  postIcon,
  location,
  textClr,
  btnClr,
  hasBorder = false,
}) {
  return (
    <a
      href={location}
      style={{
        backgroundColor: btnClr,
        color: textClr,
        borderWidth: hasBorder ? 1.04 : 0,
        width: 200,
        display: 'flex',
        justifyContent: 'space-evenly',
      }}
      className="custom_button"
    >
      {foreIcon}
      {title}
    </a>
  )
}
export function AnchorLinkHeadera({
  title,
  foreIcon,
  postIcon,
  location,
  textClr,
  btnClr,
  hasBorder = false,
}) {
  return (
    <a
      href={location}
      style={{
        backgroundColor: btnClr,
        color: textClr,
        borderWidth: hasBorder ? 1.04 : 0,
        // width: 230,
        // display: "flex",
        // justifyContent: "space-evenly",
        // marginTop:"-60px",
        // marginLeft:"-60px",
        position:'absolute',
      }}
      className="headera_button"
    >
      {foreIcon}
      {title}
    </a>
  );
}
export function RoutingBtn({
  btnTitle,
  textClr,
  btnClr,
  hasBorder,
  borderColor = '#000',
  route,
}) {
  return (
    <Link
      style={{
        color: textClr,
        backgroundColor: btnClr,
        border: hasBorder ? `0.5px solid ${borderColor}` : null,
        textDecoration: 'none',
      }}
      className="custom_btn_II custom_btn_hover"
      to={route}
    >
      {btnTitle}
    </Link>
  )
}

export function CustomBtn({
  btnTitle,
  textClr,
  btnClr,
  hasBorder,
  borderColor = '#000',
}) {
  return (
    <button
      style={{
        color: textClr,
        backgroundColor: btnClr,
        border: hasBorder ? `0.5px solid ${borderColor}` : null,
      }}
      className="custom_btn_II"
    >
      {btnTitle}
    </button>
  )
}

export function ModalBtn({
  btnTitle,
  textClr,
  btnClr,
  hasBorder,
  borderColor = '#000',
}) {
  return (
    <button
      style={{
        color: textClr,
        backgroundColor: btnClr,
        border: hasBorder ? `0.5px solid ${borderColor}` : null,
      }}
      className="custom_btn_II"
    >
      {btnTitle}
    </button>
  )
}

export function RegisterBtn() {
  return (
    <button
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
      style={{
        border: `1px solid #000`,
        height: 40,
        width: 160,
        borderRadius: 20,
        backgroundColor: 'transparent',
        marginTop: 30,
        fontFamily: `DM Sans, sans-serif`,
      }}
    >
      Register Today
    </button>
  )
}
export function HeaderaBtn() {
  return (
    <button
      className="custom_button"
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal1"
      style={{
        backgroundColor: "#FECF40",
        border: "none",
        height: 40,
        width: 100,
        borderRadius: 50,
        marginTop: 5,
        marginBottom: 20,
        fontFamily: `DM Sans, sans-serif`,
      }}
    >
      Partners
    </button>
  );
}
export function HeaderaLink({
  title,
  location,
  textClr,
  btnClr,
  hasBorder = false,
}) {
  return (
    <a
      href={location}
      style={{
        backgroundColor: "#2752D6",
        color: "white",
       display:"flex",
        alignItems: "center",
        justifyContent: "center",
        height: 40,
        width: 160,
        borderRadius: 50,
        fontFamily: `DM Sans, sans-serif`,
        marginRight: "auto",
        marginLeft: "auto",
        marginTop:"20"
      }}
    >
      {title}
    </a>
  );
}
export function HeaderaRegisterBtn() {
  return (
    <button
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal1"
      style={{
        backgroundColor: "#2752D6",
        color: "white",
        border: "none",
        height: 40,
        width: 160,
        borderRadius: 50,
        marginTop: 5,
        marginBottom: 20,
        fontFamily: `DM Sans, sans-serif`,
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      Register Now
    </button>
    
  );
}
export function HeaderaAboutBtn() {
  return (
    <button
      type="button"
      className="custom_button"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal4"
      style={{
        backgroundColor: "#FECF40",
        border: "none",
        height: 40,
        width: 160,
        borderRadius: 50,
        marginTop: 5,
        marginBottom: 20,
        fontFamily: `DM Sans, sans-serif`,
        
      }}
    >
      About this Program
    </button>
  );
}


export function JoinLink({
  title,
  foreIcon,
  postIcon,
  location,
  textClr,
  hasBorder = false,
}) {
  return (
    <a
      href={location}
      style={{
        color: textClr,
        border: `1px solid #000`,
        height: 40,
        width: 160,
        borderRadius: 20,
        backgroundColor: "transparent",
        marginTop: 30,
        fontFamily: `DM Sans, sans-serif`,
      }}
      className="custom_button"
    >
      {title}
      {foreIcon}
    </a>
  );
}
