import React from "react";
import teacherImg from "../../assets/images/homepage_assets/replacement.png";
import Img2 from "../../assets/images/headera/Group.png";
import Img from "../../assets/images/headera/X.png";
import Img3 from "../../assets/images/headera/Ellipse 1122.png";
import {
  HeaderaLink,
} from "../Button";
export default function Program() {
  return (
    <div style={style.container} className="headera_program">
      <div className="container mt-5">
        <div className="row ">
          <div className="col-md-6">
            <div className="">
              <img
                src={Img}
                style={{ maxWidth: "100%" }}
                alt=""
                srcset=""
                className="p-4"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="headera_text_wrapper m-auto mt-3">
              <img
                src={Img2}
                style={{ maxWidth: "40%" }}
                alt=""
                srcset=""
                className="py-4"
              />
              <h1>About The Hashgraph Association</h1>
              <p className="mb-4">
                The Hashgraph Association is at the forefront
                 of the digital enablement and empowerment of organisations 
                 through the broad adoption of Hedera-powered enterprise-grade 
                 solutions and decentralized applications, including funding training, 
                 innovation, and venture building programs globally. As a non-profit organization 
                 headquartered in Switzerland, The Hashgraph Association supports and funds innovation, 
                 research, and development that enables economic inclusion and a digital future for all, 
                 with a positive environmental, social, and governance (ESG) impact. For further information 
                about The Hashgraph Association <a href="https://hashgraph-association.com/" target="_blank" rel="noopener noreferrer"> visit here</a>  
              </p>
              <HeaderaLink
                location="https://www.linkedin.com/company/the-hashgraph-association"
                title="THA LinkendIn" />
              <div className="d-flex">
                {/* <img src={Img3} alt="" srcset="" className="" />
                <p className="mt-4 px-4">
                  <span>
                    <strong>Jennifer Rhima</strong>
                  </span>
                  <br />
                  <span>Director of Partnerships </span>
                  <br />
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const style = {
  container: {},
};
