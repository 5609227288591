import React from 'react'
// images
import adanianLogo from '../../assets/svg/adanian_logo.svg'
import Emurgo from '../../assets/images/about/emurgo.png'
import Crysense from '../../assets/images/about/crypsense.png'
import Arrow from '../../assets/images/about/arrow.png'
import Algorand from '../../assets/images/about/algorand-3645166074.png'
import bgImg2 from '../../assets/images/bcg_blur_two.png'
import Img1 from '../../assets/images/about/initial.png'
import Img2 from '../../assets/images/about/john_kamara.png'
import Img3 from '../../assets/images/about/jeff.png'
import FixedWidthWrapper from '../FixedWidthWrapper'

import PieLogo from '../../assets/svg/training/train1.svg'
import Archive from '../../assets/images/about/valueIcon/archive.png'
import Folder from '../../assets/images/about/valueIcon/folder.png'

import HeadPhone from '../../assets/images/about/valueIcon/headphones.png'
import Board from '../../assets/images/about/valueIcon/board.png'
import Chart from '../../assets/images/about/valueIcon/chart.png'

export default function Mission() {
  let ValueOne = [
    {
      id: 1,
      title: 'Build for future',
      text:
        'We want to build a community which is not only concentrating on current needs but also trying to predict and innovate for the future needs. Hence our technologies will endure the test of time and all our systems will be ahead of times.',
      icon: HeadPhone,
    },
    {
      id: 2,
      title: 'Decentralised ownership',
      text:
        'ABC believes that if one brain can make an initiative successful then what magic 1000s of minds can do together. The power is distributed equally among everyone who has committed to be part of ABC.',
      icon: Chart,
    },
    {
      id: 3,
      title: 'Onboarding',
      text:
        'Сreated a service that allows to systematize and facilitate the process of creating calculation files and analytical reports on the work done',
      icon: Board,
    },
  ]

  let ValueTwo = [
    {
      id: 1,
      title: 'Innovate and Adopt',
      text:
        'Being a company which is driven by technology, we always look out for innovation that will make our systems and software more effective. We want our community to be a space for innovation that can be adopted to make our systems stronger and better.',
      icon: Archive,
    },
    {
      id: 2,
      title: 'Inclusivity',
      text:
        'Every single person who becomes part of our community should always feel part of the Community. Even though we are a technology based company we understand the need for business acumen in working for a company and hence we are building an inclusive Community .',
      icon: Folder,
    },
    {
      id: 3,
      title: 'Create Talent',
      text:
        'ABC strongly believe that it is our duty to empower more and more young people for the coming generations and we wish to create a learning environment that gives chance to everyone who has the zeal to learn and promote a culture where we learn by doing.',
      icon: PieLogo,
    },
  ]

  return (
    <section
      className="mission_container"
      style={{ backgroundImage: `url(${bgImg2})`, position: "relative" }}
    >
      <img src={Arrow} alt="" className="customArrowPositioning" />
      <div className="mission_partner_wrapper md-d-block d-none ">
        <div className="mission_partners ">
          <img src={adanianLogo} alt="" />
          <img src={Crysense} alt="" />
          <img
            src="./images/impact_scope.svg"
            alt=""
          />
          <img src={Algorand} alt=""  />
        </div>
      </div>

      <div className="mission_statment_container">
        <div className="mission_state_wrapper">
          <section>
            <img src={Img1} alt="" className='mission-img'/>
            <div className="flex_images d-none d-block">
              <div>
                <img src={Img2} alt="" />
              </div>
              <div>
                <img src={Img3} alt="" />
              </div>
            </div>
          </section>

          <section>
            <h3>Our Mission In Action</h3>
            <p>
              To accentuate the pan-African Web3 revolution through training
              10,000 Web3 developers in the next 5 years
            </p>
            <h3>Our Vision Statement</h3>
            <p>
              To be a pan-african leader in Web3 development across multiple
              chains and create a resource pool of talent to be the home of
              blockchain.
            </p>
          </section>
        </div>
      </div>
      {/* 
      <div className="vision_statement_container"></div> */}

      <div className="our_vision_container">
        <h3 className="text-center our_values_header">Core Value</h3>
        <FixedWidthWrapper>
          <p className="our_values_text">
            Integrity in the delivery of our mission
          </p>
        </FixedWidthWrapper>

        <div className="values_container">
          <div className="value_statment">
            {ValueOne.map((_) => (
              <div key={_.id}>
                <div>
                  <img alt="" src={_.icon} />
                  <h3>{_.title}</h3>
                </div>
                <p>{_.text}</p>
              </div>
            ))}
          </div>
          <div className="value_statment" style={{ borderBottom: "none" }}>
            {ValueTwo.map((_) => (
              <div key={_.id}>
                <div>
                  <img alt="" src={_.icon} />
                  <h3>{_.title}</h3>
                </div>

                <p>{_.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
