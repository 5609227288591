import React from "react";
import {
  AnchorLink,
  AnchorLinkHeadera,
  HeaderaBtn,
  HeaderaLink,
  HeaderaRegisterBtn,
  Hederabtn,
  JoinLink,
  LinkButton,
  ModalBtn,
} from "../Button";
import { RiArrowRightUpLine } from "react-icons/ri";
import { BiGridVertical } from "react-icons/bi";
import Img from "../../assets/images/headera/Group 1171274848.png";

export default function Join() {
  return (
    <div className="mt-5">
      <div className="row ">
        <h3 className="text-center training_header"> How to Join</h3>
        <div className="col-md-6">
          <div className="">
            <img
              src={Img}
              style={{ maxWidth: "100%" }}
              alt=""
              srcset=""
              className="p-4"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="headera_text_wrapper m-auto mt-3">
            <ol className="" style={{ fontSize: 16 }}>
              <li>
                Check Eligibility: Ensure you meet the basic eligibility
                criteria (tech student or early-career professional).
              </li>
              <li>
                Prepare Your Application: Gather necessary documents, including
                a resume and a statement of interest.
              </li>
              <li>
                Submit Application: Fill out the online application<a href="https://dlt.adanianlabs.io/" target="_blank" rel="noopener noreferrer"> form </a> 
                 and submit it along with the required documents.
              </li>
              <li>
                Wait for Selection: Our team will review applications and notify
                selected candidates.
              </li>
              <li>
                Confirm Participation: If selected, confirm your participation
                and attend the onboarding session.
              </li>
            </ol>
          </div>
        </div>
        <HeaderaLink
          location="https://dlt.adanianlabs.io/"
          title="Register Now"
        />
      </div>
    </div>
  );
}
