import React from 'react'
import BannerTwo, {
  BannerThree,
} from '../../components/BannerSection/BannerTwo'
import JumbotronBlog from '../../components/blog/Jumbotron'
import '../../assets/stylings/blogStyling.css'
import Posts from '../../components/blog/Posts'

export default function Blog() {
  return (
    <>
      <BannerThree body={<JumbotronBlog />} />
      <Posts />
    </>
  )
}
