import React, { useState } from 'react'
import BannerTwo from '../../components/BannerSection/BannerTwo'
import Jumbotron from '../../components/our-solutions/Jumbotron'
import '../../assets/stylings/OurSolutions.css'
import SolutionDetails from '../../components/our-solutions/SolutionDetails'
import BlockChainAsService from '../../components/our-solutions/BlockChainAsService'
import FixedWidthWrapper from '../../components/FixedWidthWrapper'
import SolutionInput, {
  SolutionDropDown,
} from '../../components/our-solutions/SolutionInput'
import phone from '../../assets/svg/phone.png'
import { FiX } from 'react-icons/fi'
import { PostRequest } from '../../assets/utils/http'

export default function OurSolution() {
  let [firstName, setFirstName] = useState('')
  let [lastName, setLastName] = useState('')
  let [email, setEmail] = useState('')
  let [phone_number, setPhone] = useState('')
  let [gender, setGender] = useState('')
  let [entity, setEntity] = useState('')
  let [city, setCity] = useState('')
  let [country, setCountry] = useState('')
  let [experience, setExperience] = useState('')

  let [_loading, _setLoading] = useState(false)
  let [_message, _setMessage] = useState('')

  let submitEnrollment = async (e) => {
    e.preventDefault()
    _setLoading(true)
    _setMessage('Submitting! Please Wait!!')
    let request = await PostRequest('enroll', {
      full_name: `${firstName} ${lastName}`,
      email,
      phone_number,
      gender,
      entity,
      city,
      country,
      experience,
    })

    setFirstName('')
    setLastName('')
    setEmail('')
    setPhone('')
    setGender('')
    setEntity('')
    setCity('')
    setCountry('')
    setExperience('')

    _setMessage(request.message)
  }
  return (
    <>
      <BannerTwo body={<Jumbotron />} />
      <SolutionDetails />
      <BlockChainAsService />
      <div className="classes_bottom_banner">
        <FixedWidthWrapper>
          <h3>
            A human-centric approach is at the core of our principles, and we
            build products for the many based on the diverse perspectives and
            life experiences of our team.
          </h3>
        </FixedWidthWrapper>
      </div>
      {/* modal */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl  modal-dialog-centered  modal-dialog-scrollable">
          <div class="modal-content">
            <div className="close_btn_modal" data-bs-dismiss="modal">
              <FiX color="#fff" size={25} />
            </div>
            <div class="modal-body">
              <form onSubmit={submitEnrollment}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 order-5 order-md-1 _form_section">
                      <div className="row">
                        <div className="col-md-6">
                          <SolutionInput
                            placeholder="First Name"
                            value={firstName}
                            setValue={setFirstName}
                          />
                        </div>
                        <div className="col-md-6">
                          <SolutionInput
                            placeholder="Last Name"
                            value={lastName}
                            setValue={setLastName}
                          />
                        </div>
                      </div>
                      <SolutionInput
                        placeholder="Your Email"
                        value={email}
                        setValue={setEmail}
                      />
                      <SolutionInput
                        placeholder="Phone Number"
                        value={phone_number}
                        setValue={setPhone}
                      />
                      <div className="row">
                        <div className="col-md-4">
                          <SolutionDropDown
                            placeholder="Gender"
                            value={gender}
                            setValue={setGender}
                          >
                            <option value="male">male</option>
                            <option value="female">female</option>
                          </SolutionDropDown>
                        </div>
                        <div className="col-md-8">
                          <SolutionDropDown
                            placeholder="Select Entity"
                            value={entity}
                            setValue={setEntity}
                          >
                            <option value="student">Student</option>
                            <option value="mentor">Mentor</option>
                            <option value="trainer">Trainer</option>
                          </SolutionDropDown>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <SolutionInput
                            placeholder="Enter Your City"
                            value={city}
                            setValue={setCity}
                          />
                        </div>
                        <div className="col-md-8">
                          <SolutionInput
                            placeholder="Enter Your Country"
                            value={country}
                            setValue={setCountry}
                          />
                        </div>
                      </div>
                      <SolutionDropDown
                        placeholder="How Many Years of Experience With Blockchain Development?"
                        value={experience}
                        setValue={setExperience}
                      >
                        <option value="Less than one">Less than one</option>
                        <option value="One to Three">One to Three</option>
                        <option value="Three and above">Three and above</option>
                        <option value="None">None</option>
                      </SolutionDropDown>
                      {_loading && (
                        <div className="alert alert-primary text-center">
                          {_message}
                        </div>
                      )}
                      <div className="_modal_btn_cont">
                        <button className="_modal_btn">Submit</button>
                      </div>
                    </div>
                    <div className="col-md-4 order-1 order-md-5 _form_section">
                      <h3 className="FONT_DM_SANS _form_section_header">
                        Register to gain access to world class resources that
                        will push you to the next level.
                      </h3>
                      <h6
                        className="FONT_DM_SANS"
                        style={{ fontSize: 13, color: '#34368A' }}
                      >
                        Preferred method of communication
                      </h6>
                      <div className="d-flex align-items-center comms_option">
                        <div>
                          <input type="radio" name="options" /> <h6>phone</h6>
                        </div>
                        <div>
                          <input type="radio" name="options" /> <h6>email</h6>
                        </div>
                      </div>

                      <div className="mt-1 d-flex align-items-center">
                        <img src={phone} />{' '}
                        <h3
                          className="ps-3 FONT_DM_SANS"
                          style={{ fontSize: 22, fontWeight: 'light' }}
                        >
                          +254 722 703239
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* <div class="modal-footer"> */}
            {/* <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  )
}
