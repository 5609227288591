import React, { useState, useEffect } from 'react'
import johnpng from '../../assets/images/john_kamara.png'
import ArrowRight from '../../assets/svg/ArrowRight'

import slider1 from '../../assets/images/homepage_assets/slider_options/Capacity-Building-_-BAAS-II.webp'
import slider2 from '../../assets/images/homepage_assets/slider_options/Research-_-Development.webp'
import slider3 from '../../assets/images/homepage_assets/slider_options/Product-Development.webp'
import slider4 from '../../assets/images/homepage_assets/slider_options/Incubation.webp'

export default function WhatWeDo() {
  let [imgRender, setImgRender] = useState(0)

  let sliderOptions = [
    {
      id: 1,
      title: 'Capacity Building & BAAS',
      body:
        'Proficient blockchain developers have been cited as one of the most in-demand career skills & jobs by both the public & private sector in Africa. Due to a lack of professional in depth educational content, proficient blockchain developers are estimated to make up less than half of a percent of all software developers worldwide.',
    },
    {
      id: 2,
      title: 'Research & Development',
      body:
        'We bring together Africa’s top industry leaders, academics, policy-makers & research to support & contribute to the public and technical discourse through research & education. We mainly aim to investigate key concepts in the web3 ecosystem focusing on sustainable development goals in Africa through understanding the problem.',
    },
    {
      id: 3,
      title: 'Product Development',
      body:
        'We develop innovative inhouse enterprise-grade solutions in any market sector to provide a solid value proposition for all actors within the perceived ecosystem. The products developed purely follow the Web3 standards and are efficient, seamless and easy',
    },
    {
      id: 4,
      title: 'Incubation',
      body:
        'The Africa Blockchain Center supports innovative African projects that have elements which provide them entry in the web3 space. The key aspect is to nurture, grow and scale the projects through the advanced development skill set, knowledge within the web3 ecosystem and introduction to the ABC partner network ',
    },
  ]

  let sliderControls = [
    {
      id: 1,
      img: slider1,
    },
    {
      id: 2,
      img: slider2,
    },
    {
      id: 3,
      img: slider3,
    },
    {
      id: 4,
      img: slider4,
    },
  ]

  const clickToChangeImg = (id) => {
    setImgRender(id - 1)
  }

  const changeOnHover = (id) => {
    setImgRender(id - 1)
  }

  const changeByTimer = () => {
    setTimeout(() => {
      if (imgRender === 3) {
        setImgRender(0)
        return
      }
      setImgRender(imgRender + 1)
    }, 3000)
  }

  useEffect(() => {
    // changeByTimer()
  }, [imgRender])

  return (
    <section>
      <h3 className="text-center training_header">What We Do</h3>
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex flex-row align-items-center">
            <div style={{ marginRight: 30 }}>
              {sliderControls.map((_control) => (
                <div
                  key={_control.id}
                  className={`slides_options_control ${
                    _control.id === imgRender + 1
                      ? `slides_options_control_hover`
                      : null
                  }`}
                  onClick={() => clickToChangeImg(_control.id)}
                >
                  {_control.id}
                </div>
              ))}
            </div>

            <img
              src={sliderControls[imgRender].img}
              alt=""
              style={{ width: '70%' }}
            />
          </div>
          <div className="col-md-6">
            {sliderOptions.map((slider) => (
              <div
                key={slider.id}
                className="options_"
                onMouseEnter={() => changeOnHover(slider.id)}
              >
                <h6 style={{ paddingLeft: 25, color: '#1F2B5F' }}>
                  <ArrowRight />
                  <span style={{ paddingLeft: 15 }}>{slider.title}</span>
                </h6>
                <div className="options_body">{slider.body}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
