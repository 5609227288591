import React from 'react'
import FullBanner from '../../components/BannerSection/FullBanner'
import PolicyHome from '../../components/privacy_policy/Index'

export default function PrivacyPolicy() {
  return (
    <>
      <FullBanner body={<PolicyHome />} />
    </>
  )
}
