import React from 'react'
import BannerTwo, {
  BannerThree,
} from '../../components/BannerSection/BannerTwo'
import ContactBanner from '../../components/contactus/ContactBanner'
import ContactContent from '../../components/contactus/ContactContent'
import '../../assets/stylings/contactStyle.css'

export default function ContactUs() {
  return (
    <>
      <BannerThree body={<ContactBanner />} />
      <ContactContent />
    </>
  )
}
