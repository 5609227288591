import React from 'react'
import Emurgo from '../../assets/images/partner_logos/Emargo.png'
import Octavo from '../../assets/images/partner_logos/Octave.png'
import AlgoRand from '../../assets/images/partner_logos/ALgorand.png'
import DistLabs from '../../assets/images/partner_logos/DistLabs.png'
import LawUni from '../../assets/images/partner_logos/LAw.png'
import Quest from '../../assets/images/partner_logos/Quest3.png'
import Uni from '../../assets/images/partner_logos/Jkuat.png'
import LBank from '../../assets/images/partner_logos/Lbank.png'
import TDefi from '../../assets/images/partner_logos/TDeFi.png'
import Akash from '../../assets/images/partner_logos/Akash.png'
import IoTex from '../../assets/images/partner_logos/IoTex.png'
import Nonce from '../../assets/images/partner_logos/Nonce.png'
import Crypsense from '../../assets/images/partner_logos/Crypsense.png'
import ImpactScope from '../../assets/images/partner_logos/Impact.png'
import CodeSpace from '../../assets/images/partner_logos/CodeSpace.png'
import Dhealth from '../../assets/images/partner_logos/DHelth.png'
import Afadao from '../../assets/images/partner_logos/DAO.png'
import GotBit from '../../assets/images/partner_logos/Gobit.png'
import Cedrec from '../../assets/images/partner_logos/Cedrecs.png'

export default function TopPlayers() {
  const Partners = [
    { img: Octavo },
    { img: AlgoRand },
    { img: DistLabs },
    { img: LawUni },
    { img: Quest },
    { img: Uni },
    { img: LBank },
    { img: TDefi },
    { img: Akash },
    { img: IoTex },
    { img: Nonce },
    { img: Crypsense },
    { img: ImpactScope },
    { img: CodeSpace },
    { img: Dhealth },
    { img: Afadao },
    { img: GotBit },
    { img: Cedrec },
  ]

  return (
    <section className="topPlayerWrapper">
      <h3 className="text-center">Integrated with top players</h3>
      <p>
        Join our mission to build lasting and sustainable products. Our values
        are at the heart of what we do and we are all about getting the job done
        while having a good time. It is our intention to flip the table on the
        industry and build a blockchain without creating a native currency.
      </p>
      <div className="partnerWrapper">
        {Partners.map((_, id) => (
          <div className="partnerImgs" key={id + 1}>
            <img
              src={_.img}
              alt=""
              style={{
                maxWidth: '100%',
                backgroundColor: '#fff',
                borderRadius: 20,
              }}
            />
          </div>
        ))}
      </div>
    </section>
  )
}
