import React from "react";

import Img from "../../assets/images/headera/Dodge.png";

export default function Banner3() {
  return (
    <div className=" mt-2">
      <div className="row ">
        <h3 className="text-center training_header">Benefits</h3>
        <div className="col-md-6">
          <div className="">
            <img
              src={Img}
              style={{ maxWidth: "100%" }}
              alt=""
              srcset=""
              className="p-4"
            />
          </div>
        </div>
        <div className="col-md-5">
          <div className="headera_text_wrap m-auto mt-3">
            <h3 className="" style={{ fontSize: 34 }}>
              What You Will Get When You Join the Program
            </h3>
            <ul className="" style={{ fontSize: 16 }}>
              <li>
                <strong>Skill Development:</strong> Gain in-depth knowledge and
                hands-on experience in using Hedera Hashgraph Distributed Ledger
                Technology (DLT). <br /> 
              </li>
              <li>
                <strong>Career Advancement:</strong> Enhance your resume with
                industry-recognized certification and practical experience.
              </li>
              <li>
                <strong>Networking: </strong> Connect with industry leaders,
                potential employers, and peers who share your passion for
                technology.
              </li>
              <li>
                <strong>Innovation:</strong> Be at the forefront of DLT innovation and contribute to the
                development of cutting-edge solutions.
              </li>
              <li>
                <strong> Mentorship:</strong> Receive guidance and support from experts in the field to help
                you navigate your career path.
              </li>
              <li>
                <strong> Community:</strong> Become part of a vibrant community of tech enthusiasts and
                innovators.
              </li>
              {/* <li>
                <strong>Entrepreneurship Exchange Program (EEP):</strong>
                Jumpstart your Web3 projects through participation in the EEP.
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
