import React from 'react'
import bgImg from '../../assets/images/bg_1.png'
import Navigation from '../Navigation'

export default function BannerOne({ body }) {
  return (
    <div
      style={{ backgroundImage: `url(${bgImg})` }}
      className="pt-2 pb-5 banner_bg_control"
    >
      <Navigation />
      {body}
    </div>
  )
}
