import React from 'react'
import { AnchorLink, LinkButton } from '../Button'
import { FaDiscord } from 'react-icons/fa'

export default function Banner() {
  return (
    <div className="d-flex align-items-center flex-column">
      <section className="info_section mt-5">
        <h3 className="text-center">
          Building Africa’s Distributed Ledger Technology Ecosystem
        </h3>

        <p className="text-center">
          Africa Blockchain Center aims to build a blockchain ecosystem in
          Africa that breeds highly skilled blockchain engineers who will
          utilize every facet of the technology in building highly robust
          applications that will evolve society and the world as a whole as well
          as create demand for blockchain in Africa
        </p>
        <div className="d-flex justify-content-center">
          {/* <LinkButton
            title="Explore Our Solutions"
            textClr="#000"
            hasBorder={true}
          /> */}
          <AnchorLink
            title="Join Discord Community"
            textClr="#fff"
            btnClr="#34368A"
            location="https://discord.gg/aNPUEQpv"
            foreIcon={<FaDiscord size={18} />}
          />
        </div>
      </section>
    </div>
  )
}
