import React from 'react'
import bgImg from '../../assets/images/bg_2.png'
import Navigation from '../Navigation'

export default function FullBanner({ body }) {
  return (
    <div
      className="pt-2 pb-5 banner_bg_control"
      style={{ backgroundImage: `url(${bgImg})`, height: '100%' }}
    >
      <Navigation />
      {body}
    </div>
  )
}
