import React from 'react'
import '../assets/stylings/inputStyle.css'

export function CustomFooterInput({ placeholder, label, onChange, value ,type,name}) {
  return (
    <div className="custom_footer_input_container">
      <label htmlFor="">{label}</label>
      <input type={type} placeholder={placeholder} value={value} onChange={onChange} name={name} />
    </div>
  )
}
