import React from 'react'

export default function Jumbotron() {
  return (
    <section className="solution_banner_wrapper">
      <div>
        <div>
          <h6>We are the best there is</h6>
          <h3>
            Bring your Web 3.0 product to life with a crew that’s executed it
            all.
          </h3>
        </div>
      </div>
      <div>
        <p>
          When you launch an NFT collection, you know that you have no room to
          make mistakes and everything must be perfect, with flawless security.
          This is why brands choose to entrust the sensitive part of the smart
          contract development to ABC. Our experience and support has allowed us
          to successfully launch collections.
        </p>
      </div>
    </section>
  )
}
