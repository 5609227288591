import React from 'react'
import bgImg from '../../assets/images/bg_2.png'

export default function VideoLayer() {
  return (
    <div style={{ backgroundImage: `url(${bgImg})` }} className="video_bg">
      <div className="video_bg_layer">
        <h3 className="video_layer_header">Our Training Programs</h3>
        <p className="video_layer_subText">
          The 5 month curriculum is designed for individuals with
          <br /> intermediary experience in software engineering.
        </p>
      </div>
    </div>
  )
}
