import React from "react";
import {  AnchorLinkHeadera, HeaderaBtn,  JoinLink,  } from "../Button";
import { RiArrowRightUpLine } from "react-icons/ri";
import { BiGridVertical } from "react-icons/bi";
import Img from "../../assets/images/headera/banner1.png";


export default function Banner1() {
  return (
    <div className="container mt-5 ">
      <div className="row ">
        <div className="col-md-6 col-sm-12">
          <div className="headera_text_wrapper">
            {/* <HeaderaBtn /> */}
            <h3 className="font-bold">The DLT Africa Regional Developer Program</h3>
            <p className="" style={{ fontSize: 16 }}>
              The Hashgraph Association is powering the Adanian Labs’ DLT Africa
              Regional Developer Program (DLT-ARDP) that will offer
              Hedera-Certified Developer (HCD) training courses and
              certifications to over 3,000 developers in Africa.
            </p>
            <JoinLink
              title="Join Now"
              textClr="#000"
              location="https://dlt.adanianlabs.io/"
              foreIcon={<RiArrowRightUpLine size={18} />}
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12 ">
          <div className="headera_banner_bg">
            <img src={Img} style={{ maxWidth: "100%" }} alt="" srcset="" />
            <AnchorLinkHeadera
              title="DLT Africa Regional Developer Program"
              textClr="#fff"
              btnClr="#34368A"
              foreIcon={<BiGridVertical size={18} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
