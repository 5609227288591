import React from 'react'
import FixedWidth from '../../components/FixedWidthWrapper'
import '../../assets/stylings/careers.css'
import { CustomBtn } from '../Button'
import Jobs from './Jobs'
import img2 from '../../assets/images/empty_state/upcoming.png'

export default function Banner() {
  let types = [
    {
      id: 1,
      title: 'View All',
    },
    {
      id: 2,
      title: 'Design',
    },
    {
      id: 3,
      title: 'Marketing',
    },
    {
      id: 4,
      title: 'Customer Service',
    },
    {
      id: 5,
      title: 'Finance',
    },
    {
      id: 6,
      title: 'Engineering',
    },
  ]
  return (
    <section className="career_banner">
      <h5 className="FONT_DM_SANS flex_center">We are always hiring</h5>
      <h3 className="FONT_FAM_II text-center">
        Get to be part of our mission
        <br /> and do what you love
      </h3>
      <FixedWidth>
        <p className="FONT_DM_SANS">
          We're looking for passionate people to join us on our mission. We
          value honesty, clear communication, full ownership and responsibility.
          If you have a curious mind, enjoy a mix of fun camaraderie and hard
          core crunch-time, and want to build the future of blockchain and web
          3, then we want to hear from you!
        </p>
      </FixedWidth>

      {/* <CustomBtn
        btnClr="#34368A"
        textClr="#fff"
        btnTitle="make an open application"
      /> */}

      {/* <div className="types_container">
        {types.map((type) => (
          <CustomBtn
            key={type.id}
            btnTitle={type.title}
            btnClr="transparent"
            textClr="#000"
            hasBorder={true}
          />
        ))}
      </div> */}

      <hr className="career_divider" />

      <section className="d-flex flex-column justify-content-center align-items-center">
        <img src={img2} alt="" style={{ maxWidth: '50%' }} />
        <h5
          style={{
            textAlign: 'center',
            fontSize: 30,
            marginTop: 50,
            color: '#000',
          }}
          className="FONT_FAM_II"
        >
          No Past Jobs were
          <br /> posted by ABC
        </h5>
      </section>

      {/* {[0, 1, 2, 3, 4, 5, 6].map((_) => (
        <Jobs key={_ + 1} />
      ))} */}
    </section>
  )
}
