import axios from 'axios'

export const ROUTES = {
  CONTACTS: 'contactus',
}

let URI_ROOT = "http://api.theafricablockchaincenter.com/";

export const PostRequest = async (path, body) => {
  try {
    let call = await axios.post(`${URI_ROOT}${path}`, body)
    return call.data
  } catch (error) {
    return error.response.data
  }
}

export const SECURED_POST = async (path, body) => {
  try {
    let call = await axios.post(`${URI_ROOT}${path}`, body)
    return call.data
  } catch (error) {
    return error.response.data
  }
}

export const HttpWithFetch = async (params, path, method) => {
  let body = JSON.stringify(params)

  var myHeaders = new Headers()
  myHeaders.append('Accept', 'application/json')
  myHeaders.append('Content-Type', 'application/json')

  var requestOptions = {
    method,
    headers: myHeaders,
    body: method === 'POST' && body,
    redirect: 'follow',
  }

  fetch(`${URI_ROOT}${path}`, requestOptions)
    .then((response) => {
      console.log(response)
      return response.text()
    })
    .then((result) => {
      console.log(result)
      return result
    })
    .then((result) => {
      return result
    })
    .catch((error) => {
      console.log('error', error)
      return error
    })
}
