import React from "react";
import Img from "../../assets/images/headera/Mask group.png";
import Image1 from "../../assets/images/headera/Group 1 (2).png";
import Image2 from "../../assets/images/headera/Group 1171274865 (1).png";
import Image3 from "../../assets/images/headera/Group 1171274866.png";

export default function Banner2() {
     let ValueOne = [
       {
         id: 1,
         title: "DLT Africa Empowers 3,000+ Developers",
         text: "The DLT Africa Regional Developer Program aims to train and certify over 3,000 developers across Africa, signifying its extensive reach and impact on the continent's tech ecosystem.",
         icon: Image1,
       },
       {
         id: 2,
         title: "Commitment to Education in Africa",
         text: "The program is supported by The Hashgraph Association (THA), highlighting their strong commitment to advancing Web3 education in Africa.",
         icon: Image3,
       },
       {
         id: 3,
         title: "Top Training from Industry Leaders",
         text: "The program offers participants a wealth of knowledge and insights from industry leaders, enriching their learning experience and ensuring they receive top-tier training  distributed ledger technology.",
         icon: Image2,
       },
     ];

  return (
    <div className=" ">
      <div className="row our_vision_container">
        <h3 className="text-center our_values_header">
          Investment Opportunities
        </h3>
        <div className="col-md-7 mb-5">
          <div className="">
            <img
              src={Img}
              style={{ maxWidth: "100%" }}
              alt=""
              srcset=""
              className="p-4 d-none d-md-block "
            />
          </div>
        </div>
        <div className="col-md-5 mb-5">
          <div className=" mx-auto mt-3 px-4">
            <p
              className=""
              style={{
                fontSize: 20,
                height: "356px",
                
              }}
            >
              The Hashgraph Association is committed to nurturing promising
              projects that emerge from this hackathon. We are prepared to
              support final projects that demonstrate significant potential and
              align with the Hedera ecosystem. The support comes with knowledge
              and expertise, providing a pathway for projects to scale and
              succeed in the broader African market.
            </p>
          </div>
        </div>
        <div className="headera_container1 mt-5">
          <div className="headera_statment gap-20">
            {ValueOne.map((_) => (
              <div key={_.id}>
                <div className="">
                  <img
                    alt=""
                    src={_.icon}
                    style={{ width: 200 , height:200 }}
                    className="mt-5 "
                  />
                  <h3>{_.title}</h3>
                </div>
                <p className="">{_.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
