import React from 'react'
import teacherImg from '../../assets/images/homepage_assets/replacement.png'

export default function Extra() {
  return (
    // style added to landingPageStyle
    <div style={style.container} className="extra_style">
      <div>
        <h5>
          Complex custom projects, over the top drops,intricate enterprise apps,
          infrastructures from the future.
        </h5>
      </div>
    </div>
  )
}

const style = {
  container: {},
}
