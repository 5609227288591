import React from 'react'
import BannerTwo from '../BannerSection/BannerTwo'

export default function ContactBanner() {
  return (
    <div className="contact_banner_text_wrapper">
      <h4 className="FONT_FAM_II contact_banner_text">
        Let’s Start a<br /> Conversation
      </h4>
    </div>
  )
}
