import React from "react";
import bgImg1 from "../../assets/images/headera/background.png";
import map from "../../assets/images/headera/map.png";
import woman from "../../assets/images/headera/image 30.png";
import wave from "../../assets/images/headera/wave.png";
import star from "../../assets/images/headera/Vector (11).png";

export default function Countries() {
  return (
    <div className="mt-5 headera_container">
      <h3 className="text-center our_values_header mt-5">Countries Covered</h3>
      <p className="text-center headera_text_sub ">
        The program is being rolled out in the following key countries: Kenya,
        <br />
        South Africa, Malawi, Nigeria, Rwanda
      </p>
      <div
        style={{ backgroundImage: `url(${bgImg1})` }}
        className="hedera_bg mt-5"
      >
        <div className="headera_bg_layer">
          <div className="headera_card">
            <h3 className="mt-3">
              Kenya,
              <br /> South
              <br /> Africa,
              <br /> Malawi,
              <br /> Nigeria, <br /> Rwanda
            </h3>
          </div>
          <img
            src={map}
            // style={{ maxWidth: "40%", position: "absolute", bottom: 30 }}
            alt=""
            srcset=""
            className="headera_image"
          />
          <img
            src={wave}
            style={{
              maxWidth: "30%",
              position: "absolute",
              left: 220,
            }}
            alt=""
            srcset=""
            className="d-none d-md-block"
          />
          <img
            src={star}
            style={{
              maxWidth: "50%",
              position: "absolute",
              left: 260,
              bottom: 200,
            }}
            alt=""
            srcset=""
            className="d-none d-md-block"
          />
          <img
            src={woman}
            style={{
              maxWidth: "20%",
              position: "absolute",
              bottom: 0,
              right: 50,
            }}
            alt=""
            srcset=""
            className="d-none d-md-block"
          />
        </div>
      </div>
    </div>
  );
}
