import React from 'react'
import Loader from '../assets/images/gif/abc_gif.gif'

export default function AbcPreloader() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        height: '100%',
        width: '100%',
        background: '#fff',
      }}
    >
      <img src={Loader} alt="" srcset="" />
    </div>
  )
}
