import React from "react";
// images
import Vector1 from "../../assets/images/headera/Vector (7).png";
import Vector2 from "../../assets/images/headera/Vector (6).png";
import Vector3 from "../../assets/images/headera/Vector (10).png";
import Vector4 from "../../assets/images/headera/Vector.png";
import Vector5 from "../../assets/images/headera/Vector (8).png";
import Vector6 from "../../assets/images/headera/Vector (9).png";

export default function Banner5() {
  let ValueOne = [
    {
      id: 1,
      title: "Hedera Network Architecture",
      text: "Diving in the indepth concepts of the Hedera network and the best principles of development",
      icon: Vector1,
    },
    {
      id: 2,
      title: "Hedera Core Concepts",
      text: "Demistify the core concepts that define the functionality and structure of the Hedera network",
      icon: Vector2,
    },
    {
      id: 3,
      title: "Hedera Developer Fundamentals",
      text: "Start you're journey in developing solutions using the Hedera SDKs",
      icon: Vector3,
    },
  ];

  let ValueTwo = [
    {
      id: 1,
      title: "Local Network Setup",
      text: "Developers will be able to setup their local network comprising of a mirror node and consensus node",
      icon: Vector4,
    },
    {
      id: 2,
      title: "dApp Workshop",
      text: "Learn and develop decentralized applications with different business models on the Hedera network",
      icon: Vector5,
    },
    {
      id: 3,
      title: "JSON-RPC Relay (Native Services)",
      text: "Understand the critical aspects of connecting to Hedera neworks over RPC",
      icon: Vector6,
    },
  ];
  return (
    <section className="" style={{ position: "relative" }}>
      <div className="our_vision_container">
        <h3 className="text-center our_values_header">What We Cover</h3>
        <div className="values_container">
          <div className="headera_statment gap-4 ">
            {ValueOne.map((_) => (
              <div
                key={_.id}
                className=" "
                style={{
                  border: "1px solid #A6A4A4",
                  borderRadius: 31,
                  backgroundColor: "#FFFFFF",
                  alignItems: "flex-start",
                }}
              >
                <img
                  alt=""
                  src={_.icon}
                  className="mt-4 px-4 py-2"
                  style={{
                    alignSelf: "start",
                    width: 80,
                    height: 50,
                  }}
                />

                <h3
                  style={{
                    color: "#34368A",
                    fontWeight: "semibold",
                    textAlign: "start",
                    fontSize: 20,
                  }}
                  className="FONT_DM_SANS px-4 mt-2"
                >
                  {_.title}
                </h3>

                <p style={{ width: "100%" }} className="px-2">
                  {_.text}
                </p>
              </div>
            ))}
          </div>
          <div
            className="headera_statment gap-4"
            style={{ borderBottom: "none" }}
          >
            {ValueTwo.map((_) => (
              <div
                key={_.id}
                className="mt-4"
                style={{
                  border: "1px solid #A6A4A4",
                  borderRadius: 31,
                  backgroundColor: "#FFFFFF",
                  alignItems: "flex-start",
                }}
              >
                <img
                  alt=""
                  src={_.icon}
                  className="mt-4 px-4 py-2"
                  style={{
                    alignSelf: "start",
                    width: 80,
                    height: 50,
                  }}
                />
                <h3
                  style={{
                    color: "#34368A",
                    fontWeight: "semibold",
                    textAlign: "start",
                    fontSize: 20,
                  }}
                  className=" FONT_DM_SANS mt-2 px-4"
                >
                  {_.title}
                </h3>

                <p style={{ width: "100%" }} className="px-2">
                  {_.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
