import React from 'react'
import Img from '../../assets/images/about/about_banner_img.png'

export default function Jumbotron() {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-6">
          <div className="about_text_wrapper">
            <p className="about_p">
              Complex custom projects, over the top drops, intricate enterprise
              apps, infrastructures from the future.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="about_banner_bg">
            <img src={Img} style={{ maxWidth: '70%' }} alt="" srcset="" />
          </div>
        </div>
      </div>
    </div>
  )
}
