import React from "react";
import Image1 from "../../assets/images/headera/Image1.png";
import Image2 from "../../assets/images/headera/Image2.png";
import Image3 from "../../assets/images/headera/Image3.png";

export default function Prize() {
  let ValueOne = [
    {
      id: 1,
      title: "$1,000",
      text: "1st Prize: Recognition for the top project with a substantial reward to support further development and innovation.",
      icon: Image1,
    },
    {
      id: 2,
      title: "$700",
      text: "2nd Prize: Honors the second-best project, providing significant encouragement and support for continued growth.",
      icon: Image3,
    },
    {
      id: 3,
      title: "$500",
      text: "3rd Prize: Awards the third-place project, acknowledging outstanding effort and fostering further progress.",
      icon: Image2,
    },
  ];

 
  return (
    <section className="headera_two d-flex justify-content-center m-auto">
      <div className="headra_container">
        <h3 className="text-center our_values_header mt-5">
          Prizes and Incentives
        </h3>
        <div className=" headera_text">
          <p className="p-4 text-center ">
            We're offering substantial rewards for the most outstanding projects
            developed
            <br /> during the hackathon.
          </p>
        </div>
        <div className="headera_container mt-5">
          <div className="headera_statment" style={{ border: "none" }}>
            {ValueOne.map((_) => (
              <div key={_.id}>
                <img
                  alt=""
                  src={_.icon}
                  className=""
                  style={{ width:100 , height:120 }}
                />
                <h3 style={{ color: "white" }} className="mt-4">
                  {_.title}
                </h3>

                <p style={{ color: "white" }} className="mt-3">
                  {_.text}{" "}
                </p>
              </div>
            ))}
          </div>
          <p className="m-auto text-center mt-3 " style={{ width: "80%" }}>
            These prizes are designed to incentivize and recognize the hard
            work, creativity, and innovation of our participants. Additionally,
            winning teams may have the opportunity to receive further support
            from The Hashgraph Association, including potential investment.
          </p>
        </div>
      </div>
    </section>
  );
}
