import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import '../assets/stylings/navigation.css'
import Logo from '../assets/svg/abc_logo.svg'
import Hedera from "../assets/images/partner_logos/Group 1171274855.svg";
import { FiMenu, FiX } from 'react-icons/fi'

export default function Navigation() {
  const [toggle, setToggle] = useState(false)

  const links = [
    { title: "Discover Us", path: "discover-us", id: 1 },
    { title: "Our Solutions", path: "our-solutions", id: 2 },
    { title: "Blog", path: "blog", id: 3 },
    // { title: 'Support', path: 'discover-us', id: 4 },
    { title: "Careers", path: "careers", id: 5 },
    { title: "Events", path: "events", id: 6 },
    // { title: "Summit", path: "summit", id: 7 },
    { title: "THA X ABC", path: "thaxabc", id: 7},
    { title: "Contact Us", path: "contact-us", id: 8 },
  ];

  return (
    <>
      <div className="d-flex justify-content-center">
        <nav className="navigationContainer">
          <div className="">
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
           
          </div>

          <div>
            <ul className="d-flex flex-row landing_links">
              {links.map((link) => (
                <li className="ms-2" key={link.id}>
                  <NavLink
                    to={`/${link.path}`}
                    className={({ isActive, isPending }) =>
                      isActive
                        ? "activeMenu nav_link "
                        : isPending
                        ? "nav_link"
                        : ""
                    }
                  >
                    <div className="yellow_dot"></div>
                    {link.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>

      <nav className="navigationMobile">
        <div>
          <Link to="/">
            <img src={Logo} alt="" className="mobile_logo_img" />
          </Link>
        </div>
        <div>
          <div onClick={() => setToggle(true)} className="hidden-sm">
            <FiMenu />
          </div>
        </div>
      </nav>
      <div
        className="sidebar_cover"
        style={{ display: toggle ? "block" : "none" }}
      >
        <div className="">
          <ul className="mobile_menu">
            {links.map((link) => (
              <li key={link.id}>
                <Link to={`/${link.path}`}>{link.title}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <div
            style={{
              backgroundColor: "#34368A",
              height: 70,
              width: 70,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 70,
            }}
            onClick={() => setToggle(false)}
          >
            <FiX color="#fff" size={25} />
          </div>
        </div>
      </div>
    </>
  );
}
