import React from 'react'

export default function JumbotronBlog() {
  return (
    <section className="blog_banner_sec">
      <h3 className="jumbo_header_text">Around The Block</h3>
      <div className="blog_banner_info">
        <h3>
          Blogging on African's leading Web3 asset platform where you have
          full control of your future.
        </h3>
      </div>
    </section>
  )
}
