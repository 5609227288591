import React, { useEffect, useState } from 'react'
import Banner from '../../components/landing/Banner'
import WhatWeDo from '../../components/landing/WhatWeDo'
import Navigation from '../../components/Navigation'
import bgImg from '../../assets/images/homepage_assets/bg1.webp'
import bgImg2 from '../../assets/images/homepage_assets/middle_bg.webp'
import '../../assets/stylings/landingPageStyling.css'
import Leveraging from '../../components/landing/Leveraging'
import Reasons from '../../components/landing/Reasons'
import OurTraining from '../../components/landing/OurTraining'
import VideoLayer from '../../components/landing/VideoLayer'
import OurEvents from '../../components/landing/OurEvents'
import Extra from '../../components/landing/Extra'
import TopPlayers from '../../components/landing/TopPlayers'
import AbcPreloader from '../../components/AbcPreloader'

export default function LandingPage() {
  let [loading, setLoad] = useState(true)
  // let [screenPos, setScren]

  useEffect(() => {
    setTimeout(() => {
      setLoad(false)
    }, 2000)
  }, [])

  if (loading) {
    return <AbcPreloader />
  }

  return (
    <>
      <div
        style={{ backgroundImage: `url(${bgImg})` }}
        className="pt-2 pb-5 banner_bg_control"
      >
        <Navigation />
        <Banner />
        <div className="banner_bg_control_two">
          <section className="info_section info_sub_section pt-5">
            <h3 className="text-center">
              Engineering the Decentralized Future
            </h3>
            <p className="text-center mt-2" style={{ fontSize: 16 }}>
              The Africa Blockchain Center is an independent capacity building &
              research centre focused on blockchain technology. We bring
              together Africa’s top industry leaders, academics, policy-makers,
              entrepreneurs and researchers to undertake ground-breaking
              research on blockchain technology and accentuate the pan-African
              blockchain revolution through training 10,000 blockchain
              developers by 2027.
            </p>
          </section>
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${bgImg2})` }}
        className="banner_bg_control_three"
      >
        <WhatWeDo />
        <Leveraging />
        <Reasons />
        <OurTraining />
      </div>
      <VideoLayer />
      {/* <OurEvents /> */}
      <Extra />
      <TopPlayers />
    </>
  )
}
