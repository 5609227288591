import React from 'react'
import FullBanner from '../../components/BannerSection/FullBanner'

export default function CookiePolicy() {
  return <FullBanner body={<CookieContent />} />
}

function CookieContent() {
  return (
    <div style={{ padding: `50px 50px` }}>
      <h4>Information About Our Use of Cookies and Similar Technologies</h4>
      <p>
        The Africa Blockchain Center Limited (the “The Africa Blockchain Center
        Group,” “we,” “our,” or “us”) wants to make sure you understand how
        cookies and other similar technologies are used by us on our websites
        theafricablockchaincenter.com, and any subdomains thereof (which we may
        refer to as the “Sites”) and by our partners. These technologies have
        several purposes (including third-party advertising). Our sites use
        cookies and similar technologies to distinguish you from other users of
        our site. This helps us to provide you with a good experience when you
        browse our site and also allows us to improve our site. By continuing to
        browse any of our site, you are agreeing to our use of cookies and
        similar technologies as described in this Policy.
      </p>

      <h4>Types of Technologies That We Use</h4>
      <p>
        Cookies: A cookie is a small piece of data (text file) that a website –
        when visited by a user – asks your browser to store on your device in
        order to remember information about you, such as your language
        preference or login information. Those cookies are set by us and called
        “first-party cookies”. We also use “third-party cookies” – which are
        cookies originating from a domain different from the domain of the
        website you are visiting (e.g., one of our Sites) – generally for our
        advertising and marketing efforts. More specifically, we use cookies and
        other tracking technologies for the following purposes: to enable our
        site to function and maintain security (for example, shopping cart
        functionality, security, customer support); to track, measure, and
        improve performance of our sites; allow personalization of our Sites;
        and to target our own ads to prior visitors of our sites on third party
        websites (see below on “Why We Use Cookies and Similar Technologies”).
      </p>
      <ul>
        <li>
          A persistent cookie remains on your hard drive after you close your
          browser. Persistent cookies may be used by your browser on subsequent
          visits to one of our Sites until you choose to delete them, and
          otherwise typically delete themselves at expiration. Persistent
          cookies can be removed by following your web browser's directions.
        </li>

        <li>
          A session cookie is temporary and disappears after you close your
          browser.
        </li>
        <li>
          A third-party cookie is placed by someone other than the The Africa
          Blockchain Center Group, and may gather browsing activity across
          multiple websites and across multiple sessions. They are usually a
          type of persistent cookie and are stored until you delete them or they
          expire based on the time set in each third-party cookie.
        </li>
      </ul>
      <p>
        You can reset your web browser to refuse all cookies or to indicate when
        a cookie is being sent. However, some features of our service may not
        function properly if the ability to accept cookies is disabled. For more
        information about cookies, you may visit{' '}
        <a href="http://www.allaboutcookies.org">
          http://www.allaboutcookies.org
        </a>
        .
      </p>

      <p>
        Here’s a description of The Africa Blockchain Center Group’s sites’
        primary first-party cookies and their purposes:
      </p>

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Cookie</th>
              <th>Purpose</th>
              <th>Description</th>
              <th>Site</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>cmcsr</td>
              <td>Strictly Necessary</td>
              <td>A CSRF token used to enhance security.</td>
              <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
              <td>cmses</td>
              <td>Strictly Necessary</td>
              <td>Helps maintain a persistent user session</td>
              <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
              <td>cmfts</td>
              <td>Functional</td>
              <td>Helps us track the first time a user visited the site.</td>
              <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
              <td>cmau</td>
              <td>Strictly Necessary</td>
              <td>Stores affiliate data.</td>
              <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
              <td>cmphn</td>
              <td>Functional</td>
              <td>
                Improves site performance when you visit with a mobile device.
              </td>
              <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
              <td>cmalgn</td>
              <td>Functional</td>
              <td>Improves the login experience.</td>
              <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
              <td>cmfst</td>
              <td>Functional</td>
              <td>Helps us remember your font display settings.</td>
              <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
              <td>cmdvc</td>
              <td>Performance</td>
              <td>
                Helps us detect new devices/browsers logging into the site.
              </td>
              <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>Helps us track on-site browsing patterns.</td>
              <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
              <td>cmvts</td>
              <td>Strictly Necessary</td>
              <td>Helps us track on-site browsing patterns.</td>
              <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
              <td>cmgc</td>
              <td>Functional</td>
              <td>Helps us remember your cart before you login.</td>
              <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
              <td>cje</td>
              <td>Functional</td>
              <td>A unique ID to track affiliate data.</td>
              <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
              <td>user_session_token</td>
              <td>Strictly necessary</td>
              <td>Helps maintain a persistent user session.</td>
              <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
              <td>has_logged_in</td>
              <td>Functionality</td>
              <td>Allows us to determine if the user has logged in or not.</td>
              <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
              <td>_abc_session</td>
              <td>Strictly necessary</td>
              <td>Session store configuration used by all other cookies.</td>
              <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
              <td>trackNonAjaxFilter</td>
              <td>Functionality</td>
              <td>Allows us to track some shot filtering behaviors on site.</td>
              <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
              <td>_sp_ses, _sp_id</td>
              <td>Functionality</td>
              <td>Sets a unique session for Analytics tracking.</td>
              <td>The Africa Blockchain Center</td>
            </tr>
            {/* <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>The Africa Blockchain Center</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>The Africa Blockchain Center</td>
            </tr> */}
          </tbody>
        </table>

        <p>
          Log File Information: Log file information is automatically reported
          by your browser each time you access a web page. When you use one of
          our Sites, our servers automatically record certain log file
          information. These server logs may include information such as your
          web request, Internet Protocol ("IP") address, browser type, referring
          / exit pages and URLs, number of clicks and how you interact with
          links on the Sites, domain names, landing pages, pages viewed, and
          other such information. Web Beacons/Clear GIFs Information: Our site
          have buttons or icons provided by third-party social media providers
          (e.g., Facebook, Pinterest, and LinkedIn) that allow you to interact
          with those social media services when you interact with our site.
          Those social widgets may collect browsing data, including through
          cookies and similar technologies, which may be received by the third
          party that provided the widget, and are controlled by those
          third-parties. Please review the privacy policy and privacy settings
          of the applicable social media property before using such features on
          our Sites. Social Network Widgets: Our Service has buttons or icons
          provided by third-party social media providers (e.g., Facebook,
          Pinterest, and LinkedIn) that allows you to interact with those social
          media services when you interact with our Service. Those social
          widgets may collect browsing data, including through cookies and
          similar technologies, which may be received by the third party that
          provided the widget, and are controlled by those third-parties. Please
          review the privacy policy and privacy settings of the applicable
          social media property before using such features on our Service.
        </p>
        <h3>Why We Use Cookies and Similar Technologies</h3>
        <p>
          We use cookies and similar technologies described above to recognize
          your logged-in state on the site, to understand what purchases members
          and visitors are interested in, to make our Sites function for you,
          and to help your browsing experience and use of the Sites feel more
          customized, as further described:
        </p>

        <ol>
          <li>
            Strictly necessary. These are cookies that are required for the
            operation of our Sites or to provide the services requested by you.
            They include, for example, cookies that enable you to log into
            secure areas of our Sites, use a shopping cart, or make use of
            e-billing services.
          </li>
          <li>
            Functional. These are used to recognize you when you return to our
            Sites. This enables us to personalize our content for you, greet you
            by name and remember your preferences (for example, your choice of
            language or region).
          </li>
          <li>
            Analytical/performance. They allow us to recognize and count the
            number of visitors and to see how visitors move around our Sites
            when they are using the Sites. This helps us to improve the way our
            Sites work, for example, by ensuring that users are finding what
            they are looking for easily.
            <ol>
              <li>
                Google Analytics: The Site also uses Google Analytics to help
                understand how our Service is used by its community. For some of
                the advertising features listed below, like retargeting, data
                from Google Analytics may be combined with our first-party data
                and third-party cookies (like Google’s advertising cookies). See
                here for how Google uses data when you use our Service and how
                you may opt out (www.google.com/policies/privacy/partners/).
              </li>
              <li>
                Other Analytics Providers. We also use other third-party
                analytics services, including LinkedIn Analytics, Sift, and
                Segment. We use these analytics providers to help understand use
                of the site. These analytics services collect the information
                sent by your browser as part of a web page request, including
                cookies and your IP address, and their use of this information
                is governed by their privacy policies. You can find links to
                their privacy policies below, in the “Managing Cookies
                Technologies” section.
              </li>
            </ol>
          </li>
          <li>
            Social Networks. These are used to enable you to interact with
            third-party social-networks when logged into our site (subject to
            any preferences you set with those third-parties). Social networks
            may also work with us or with you for analytics or for marketing
            purposes, as discussed below. You may be able to manage your privacy
            preferences for these social networks and their tools and widgets
            via your account with the social network. You can find links to
            their privacy policies below in the “Managing Cookies Technologies”
            section.
          </li>
          <li>
            5. Targeting and Marketing. For third party advertising on our Sites
            (e.g., Font Squirrel), third parties that deliver ads on our Sites
            use cookies on our Sites with our permission to uniquely distinguish
            your web browser and keep track of information relating to serving
            ads on your web browser, such as the type of ads shown and the web
            pages on which the ads appeared in order to provide ads on our Sites
            that we hope will be of interest to you. We also work with
            third-parties to target advertising for our site on the third-party
            websites. These cookies record your visit to our site, the pages you
            have visited and the links you have followed. We will use this
            information to make our Sites more relevant to your interests. We
            may also share this information with third parties for this purpose.
            These third-party service providers may use various cookies and
            similar technology to permit us and them to show you ads on
            third-party websites. These may include things such as:
            <ol>
              <li>
                Frequency capping, which limits the number of times a user’s
                browser or mobile device displays the same ad;
              </li>
              <li>
                Attribution tracking, which estimates what advertising or
                marketing source brought someone to our Sites, or determines
                what marketing source led to actions like a visit or a purchase;
              </li>
              <li>
                Remarketing, which shows relevant ads to an audience based on
                prior shopping and browsing patterns on our site;
              </li>
              <li>
                Audience targeting, which refers to targeting advertisements to
                a large audience based on the audience’s known or inferred
                demographics; and
              </li>
              <li>
                Cross-device recognition, which recognizes actions across
                multiple devices or browsers.
              </li>
            </ol>
          </li>
        </ol>

        <p>
          Some third-party service providers may provide information like
          demographics, cross-device information, or interest categories from a
          combination of sources that, while not identifying you personally,
          permit us to provide you with more relevant and useful advertising. In
          some cases, this information may have non-marketing performance
          analytics uses as well. These technologies allow a partner to
          recognize your computer or mobile device each time you visit our Sites
          or other websites and mobile applications based on data like a cookie,
          your IP address, or device ID, but do not allow access to other
          personal information from our Sites.{' '}
        </p>

        <p>
          However, these technologies may allow us or a third party to recognize
          you, either from a single device or across devices, over time. These
          third parties are required to follow applicable laws, self-regulatory
          programs, and our data protection rules where applicable. However, The
          Africa Blockchain Center Group does not have control over these third
          parties, who each have their own privacy policies and privacy
          practices.
        </p>

        <p>
          these third parties, who each have their own privacy policies and
          privacy practices. You can find links to their privacy policies below
          in the “Managing Cookies Technologies” section.
        </p>
      </div>
    </div>
  )
}
