import React, { useState } from 'react'
import FixedWidth from '../../components/FixedWidthWrapper'
import BG from '../../assets/images/bcg_blur_two.png'

// imgs
import leaders from '../../assets/images/our-solution/blk_leaders.png'
import programming from '../../assets/images/our-solution/blk_programming.png'
import teens from '../../assets/images/our-solution/blk_kids.png'
import nft from '../../assets/images/our-solution/nft.png'

// services images
import {
  Frame1,
  Frame2,
  Frame3,
  Frame4,
  Frame5,
  Frame6,
} from '../../assets/images/our-solution/svgs/images/Frames'

export default function BlockChainAsService() {
  let [ourServices, setServices] = useState([
    {
      id: 1,
      icon: '',
      title: 'Capacity',
      body:
        'We specialize in delivering high quality content to participants who have registered for our training across multiple protocols in different African countries. The participants learn and advance their skills in web3 engineering leading to the development of projects powered by viable usecases for the African market.',
      img: <Frame1 color="#000" />,
      isHovered: false,
    },
    {
      id: 2,
      icon: '',
      title: 'dApps Development',
      body:
        'We specialize in developing cutting-edge decentralized applications for the web3 ecosystem. Our full stack web3 engineers use diverse programming languages and frameworks to develop scalable, secure, robust and interoperable solutions to be deployed for market.',
      img: <Frame4 color="#000" />,
      isHovered: false,
    },
    {
      id: 3,
      icon: '',
      title: 'Self-sovereign Identity',
      body:
        'We pioneer in developing self-sovereign identity solutions for businesses prioritizing user privacy and security. The developed layer provides an interoperable play in which it can be integrated across different platforms in the web2 and the web3 space',
      img: <Frame6 color="#000" />,
      isHovered: false,
    },
    {
      id: 4,
      icon: '',
      title: 'Tokenomics Engineering',
      body:
        'We can help you tokenize your assets or create custom tokens on popular blockchain platforms like Ethereum, Polygon, Cardano and Algorand. We speciliaze in empowering businesses through fundraising, loyalty programs, and fractional ownership, among others.',
      img: <Frame3 color="#000" />,
      isHovered: false,
    },
    {
      id: 5,
      icon: '',
      title: 'NFT Development',
      body:
        'Powered by our capacity building initiative, we have experts with experience in developing marketplaces, NFT tokens and NFT strategy consultation to help businesses maximize the potential of NFTs in their operations. This can be a massive value add to businesses who like storytelling to their customers.',
      img: <Frame5 color="#000" />,
      isHovered: false,
    },
    {
      id: 6,
      icon: '',
      title: 'Smart Contract Development',
      body:
        'We offer end-to-end smart contract development services, including design, coding, testing, and deployment. Whether you need a simple token contract or a complex decentralized application, we have the expertise to bring your ideas to life.',
      img: <Frame2 color="#000" />,
      isHovered: false,
    },
  ])

  let mouseHover = (id) => {
    let services = [...ourServices]
    services.map((service) => {
      if (service.id === id) {
        if (id === 1) return (service.img = <Frame1 color="#fff" />)
        if (id === 2) return (service.img = <Frame4 color="#fff" />)
        if (id === 3) return (service.img = <Frame6 color="#fff" />)
        if (id === 4) return (service.img = <Frame3 color="#fff" />)
        if (id === 5) return (service.img = <Frame5 color="#fff" />)
        if (id === 6) return (service.img = <Frame2 color="#fff" />)
      }
    })
    setServices(services)
  }

  let mouseOut = (id) => {
    let services = [...ourServices]
    services.map((service) => {
      if (service.id === id) {
        if (id === 1) return (service.img = <Frame1 color="#000" />)
        if (id === 2) return (service.img = <Frame4 color="#000" />)
        if (id === 3) return (service.img = <Frame6 color="#000" />)
        if (id === 4) return (service.img = <Frame3 color="#000" />)
        if (id === 5) return (service.img = <Frame5 color="#000" />)
        if (id === 6) return (service.img = <Frame2 color="#000" />)
      }
    })
    setServices(services)
  }

  let classes = [
    {
      id: 1,
      title: 'Blockchain Programming',
      body: `A quick way to get going with understanding the blockchain is to look at "torrents".
      In torrents, no file is owned by a single person. It is a kind of “peer-to-peer” architecture where multiple copies of the file are present at multiple workstations/users. Therefore, no single person controls that file. This kind of architecture is highly scalable and fault-tolerant since there is no single point of failure.`,
      img: programming,
    },
    {
      id: 2,
      title: 'Blockchain For Leaders',
      body:
        'In the early days of the internet, who could have seen the amazing opportunities that social media would create into world. Now, businesses can reach out millions of people, where they are, "on their phones". With the advent of the blockchains and the many tools and opportunities that springs up everyday, we have barely scratched the surface.',
      img: leaders,
    },
    {
      id: 3,
      title: 'Blockchain For Teens',
      body: `We at The African Blockchain Center (ABC) strongly believes that children are the leaders of tomorrow, and have taken upon ourselves to take on the responsiblity of introducing and preparing them for the tomorrow.
      Through a properly curated set of lessons and hands-on mentorship, children can gain valueable introduction to the blockchain and writing simple programmable money on the blockchain.`,
      img: teens,
    },
    {
      id: 4,
      title: 'NFT Masterclass',
      body: `Non-fugible Tokens (NFTs) are a big feature and common use-case on the most blockchains.
      With billions of dollars worth of transactions and exchanges happening everyday.
      Learn how it works, how to create one and how to deploy on the blockchain.`,
      img: nft,
    },
  ]

  return (
    <section
      style={{ backgroundImage: `url(${BG})` }}
      className="bc_as_service_container"
    >
      <h4 className="text-center">Our Core Web3 Services</h4>
      <FixedWidth>
        <p className="text-center">
          Use blockchain as a solution for pan-african & global problems that
          affect Africa including policy. It is our intention to flip the table
          on the industry and build innovative solutions across Africa through
          our empowered resource pools.
        </p>
      </FixedWidth>
      <div className="bc_as_service">
        {ourServices.map((_) => (
          <div
            key={_.id}
            onMouseEnter={() => mouseHover(_.id)}
            onMouseLeave={() => mouseOut(_.id)}
          >
            {_.img}
            <h6>{_.title}</h6>
            <p>{_.body}</p>
          </div>
        ))}
      </div>

      <h4 className="text-center">
        Fostering a prime & engaging
        <br /> learning environment
      </h4>
      <FixedWidth>
        <p className="text-center">
          Africa Blockchain Center aims to build a blockchain ecosystem in
          Africa that breeds highly skilled blockchain engineers who will
          utilise every facet of the technology in building highly robust
          applications that will evolve society and the world as a whole as well
          as create demand for blockchain in Africa. We focus on the details of
          everything we do. All to help businesses around the world focus on
          what's most important to them. We take pride in this
        </p>
      </FixedWidth>

      <div className="classes">
        {classes.map((_) => (
          <div key={_.id}>
            <div
              className="class_img"
              style={{ backgroundImage: `url(${_.img})` }}
            ></div>
            <div className="class_content">
              <h5>{_.title}</h5>
              <p>{_.body}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center">
        <button
          type="button"
          className="_register_btn"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Register
        </button>
      </div>
    </section>
  )
}
